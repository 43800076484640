import {
  Box,
  Button,
  Flex,
  Heading,
  Skeleton,
  Text,
  VStack
} from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Chain } from 'types/dexbarn'
import { formattedNum } from 'utils/format'

interface BalanceOverviewProps {
  chain: Chain
  marketAddress: string
  tokenSymbol: string
  isLoadingTokenBalance?: boolean
  tokenBalance?: string
}

const BalanceOverview = ({
  chain,
  isLoadingTokenBalance,
  marketAddress,
  tokenBalance,
  tokenSymbol
}: BalanceOverviewProps) => {
  const navigate = useNavigate()

  const fmtBalance = tokenBalance ? formattedNum(tokenBalance) : '0'

  return (
    <Box p={{ base: 4, md: 6 }} w="full">
      <VStack align="stretch" spacing={6}>
        <Heading size="sm">OVERVIEW</Heading>

        <VStack align="stretch" spacing={2} fontSize="sm">
          <Flex
            w="full"
            borderBottom="1px dashed"
            borderColor="border"
            justifyContent="space-between"
            pb={1}
          >
            <Text textColor="textSecondary">Balance:</Text>
            <Skeleton isLoaded={!isLoadingTokenBalance}>
              <Text>
                {fmtBalance} {tokenSymbol}
              </Text>
            </Skeleton>
          </Flex>
        </VStack>

        <Button
          bg="accent.500"
          size="lg"
          variant="boxShadowFlat"
          width="full"
          textColor="white"
          onClick={() => navigate(`/${chain}/${marketAddress}/stake`)}
        >
          [STAKE]
        </Button>
      </VStack>
    </Box>
  )
}

export default BalanceOverview
