import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { MarketData } from 'types/dexbarn'
import { formattedNum } from 'utils/format'

interface Props {
  market: MarketData
  onClick: () => void
  id?: string
  isHighlighted?: boolean
  onMouseEnter?: () => void
}

const MarketSearchResultRow = ({
  id,
  isHighlighted,
  market,
  onClick,
  onMouseEnter
}: Props) => {
  return (
    <HStack
      id={id}
      as={Link}
      aria-label={`Link to ${market.baseToken.symbol}`}
      to={`/${market.chain}/${market.marketAddress}`}
      px={4}
      py={3}
      spacing={4}
      cursor="pointer"
      _hover={{ bg: 'hover' }}
      borderRadius={0}
      bg={isHighlighted ? 'hover' : undefined}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <Image boxSize={{ base: 6, md: 8 }} src={market.iconUrl} />

      <VStack align="flex-start" spacing={0}>
        <HStack spacing={2} align="flex-start">
          <Text fontWeight="bold">{market.baseToken.name}</Text>
          <Text textColor="textSecondary">{market.baseToken.symbol}</Text>
        </HStack>

        <Text fontSize="sm">
          <Box as="span" textColor="textSecondary">
            Price:
          </Box>{' '}
          {formattedNum(market.baseToken.priceUsd, {
            usd: true
          })}
        </Text>
      </VStack>
    </HStack>
  )
}

export default MarketSearchResultRow
