import useAddErrorPopup from 'hooks/useAddErrorPopup'
import { useDexbarnClient } from 'hooks/useDexbarn'
import { useCallback } from 'react'
import { AuthTokens } from 'types/dexbarn'
import { useAccount } from 'wagmi'

export const useCreateTokenLogoUploadUrl = () => {
  const client = useDexbarnClient()
  const { address: account } = useAccount()
  const addErrorPopup = useAddErrorPopup()

  return useCallback(
    async ({
      authTokens,
      contentType
    }: {
      authTokens: AuthTokens
      contentType: string
    }) => {
      if (!account) {
        return { destinationUrl: '', fields: {}, presignedUploadUrl: '' }
      }

      try {
        const response = await client.post<{
          destinationUrl: string
          fields: { [key: string]: string }
          presignedUploadUrl: string
        }>(
          '/v1/markets/drafts/upload/posturl',
          { contentType },
          { headers: { Authorization: `Bearer ${authTokens.accessToken}` } }
        )
        const { data } = response
        return {
          destinationUrl: `https://${data.destinationUrl}`,
          fields: data.fields,
          presignedUploadUrl: data.presignedUploadUrl
        }
      } catch (e) {
        if (e instanceof Error) {
          addErrorPopup({
            subtitle: e.message,
            summary: 'Unable to create upload URL'
          })
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [account, client, addErrorPopup]
  )
}
