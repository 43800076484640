import AvalancheIcon from 'assets/avalanche.svg'
import { Chain as DexbarnChain } from 'types/dexbarn'
import {
  avalancheFuji,
  avalancheFuji as wagmiAvalancheFuji,
  Chain as WagmiChain
} from 'wagmi/chains'

export enum TokenMillChainId {
  FUJI = avalancheFuji.id,
  SOLANA_DEVNET = 901
}

type Chain = {
  dexbarnId: DexbarnChain
  iconUrl: string
  slug: string
} & WagmiChain

const fuji: Chain = {
  ...wagmiAvalancheFuji,
  blockExplorers: {
    default: {
      apiUrl: 'https://api-testnet.snowscan.xyz/api',
      name: 'SnowScan',
      url: 'https://testnet.snowscan.xyz'
    }
  },
  dexbarnId: 'avalanche',
  iconUrl: AvalancheIcon,
  slug: 'fuji'
}

export const IS_TESTNET = process.env.REACT_APP_IS_TESTNET === 'true'

// TODO: set mainnet chains
export const chains = IS_TESTNET ? ([fuji] as const) : ([fuji] as const)
