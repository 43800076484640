import {
  Box,
  Button,
  Center,
  Flex,
  Hide,
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Spacer,
  Spinner,
  Tab,
  Table,
  TableContainer,
  TabList,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import ChainIcon from 'components/ChainIcon'
import SortableTableHeader from 'components/SortableTableHeader'
import useGetTMMarkets from 'hooks/tokenmill/useGetTMMarkets'
import React, { useCallback, useState } from 'react'
import { TMMarketQueryParam } from 'types/dexbarn'
import { formatShortDistanceToNow, formattedNum } from 'utils/format'

import LiveIndicator from './LiveIndicator'

const TokenMill = () => {
  const [sortMethod, setSortMethod] =
    useState<TMMarketQueryParam.OrderBy>('new')
  const [isSortDescending, setIsSortDescending] = useState<boolean>(true)

  const filterOptions: TMMarketQueryParam.FilterBy[] = [
    '1h',
    '1d',
    '7d',
    '14d',
    '30d'
  ]
  const [filterBy, setFilterBy] = useState<TMMarketQueryParam.FilterBy>('1h')

  // TODO: this should be multichain
  const [isHoveringRow, setIsHoveringRow] = useState<boolean>(false)
  const [isPaused, setIsPaused] = useState<boolean>(false)
  const {
    data: markets = [],
    fetchNextPage: fetchNextMarkets,
    hasNextPage: hasNextMarketsPage,
    isFetchingNextPage: isFetchingNextMarketsPage,
    isLoading: isLoadingMarkets,
    pausedMarketsCount
  } = useGetTMMarkets({
    chain: 'avalanche',
    excludeLowVolumeMarkets: false,
    filterBy,
    isDesc: isSortDescending,
    isPaused: isPaused || isHoveringRow,
    orderBy: sortMethod
  })

  const onHeaderClick = useCallback(
    (method: TMMarketQueryParam.OrderBy) => {
      sortMethod !== method
        ? setSortMethod(method)
        : setIsSortDescending((previous) => !previous)
    },
    [sortMethod]
  )

  let sortMethodTabIndex = -1
  if (sortMethod === 'new') {
    sortMethodTabIndex = 0
  } else if (sortMethod === 'volume') {
    sortMethodTabIndex = 1
  } else if (sortMethod === 'pct_change') {
    sortMethodTabIndex = 2
  }

  return (
    <Box
      pt={{ base: 4, md: 6 }}
      px={{ base: 0, md: 2 }}
      maxW="1600px"
      margin="0 auto"
      pb={8}
    >
      <VStack align="flex-start">
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          gap={{ base: 4, md: 8 }}
          w="full"
          px={4}
        >
          <Tabs
            variant="bracketed"
            index={sortMethodTabIndex}
            overflowX={{ base: 'auto', md: 'visible' }}
            w={{ base: 'full', md: 'auto' }}
          >
            <TabList>
              <Tab
                onClick={() => {
                  setSortMethod('new')
                  setIsSortDescending(true)
                }}
                whiteSpace="nowrap"
              >
                New Tokens
              </Tab>
              <Tab
                onClick={() => {
                  setSortMethod('volume')
                  setIsSortDescending(true)
                }}
                whiteSpace="nowrap"
              >
                Top Volume
              </Tab>
              <Tab
                onClick={() => {
                  setSortMethod('pct_change')
                  setIsSortDescending(true)
                }}
                whiteSpace="nowrap"
              >
                Gainers
              </Tab>
            </TabList>
          </Tabs>

          <Tabs
            variant="bracketed"
            index={filterOptions.indexOf(filterBy)}
            onChange={(index) => setFilterBy(filterOptions[index])}
            overflowX={{ base: 'auto', md: 'visible' }}
            w={{ base: 'full', md: 'auto' }}
          >
            <TabList>
              {filterOptions.map((option) => (
                <Tab key={option} whiteSpace="nowrap">
                  {option.toUpperCase()}
                </Tab>
              ))}
            </TabList>
          </Tabs>

          <Hide below="md">
            <Spacer />
          </Hide>

          <LiveIndicator
            isPaused={isPaused || isHoveringRow}
            onTogglePause={() => setIsPaused(!isPaused)}
            pausedDataCount={pausedMarketsCount}
          />
        </Flex>

        {isLoadingMarkets ? (
          <Center w="full" minH="70svh">
            <Spinner />
          </Center>
        ) : (
          <TableContainer w="full" minH="70svh">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Token</Th>
                  <Th textAlign="right">Price</Th>
                  <SortableTableHeader
                    name={t`Change`}
                    isSortActive={sortMethod === 'pct_change'}
                    isSortDescending={isSortDescending}
                    isNumeric
                    onClick={() => onHeaderClick('pct_change')}
                  />
                  <SortableTableHeader
                    name={t`Age`}
                    isSortActive={sortMethod === 'new'}
                    isSortDescending={isSortDescending}
                    isNumeric
                    onClick={() => onHeaderClick('new')}
                  />
                  <SortableTableHeader
                    name={t`Volume`}
                    isSortActive={sortMethod === 'volume'}
                    isSortDescending={isSortDescending}
                    isNumeric
                    onClick={() => onHeaderClick('volume')}
                  />
                  <SortableTableHeader
                    name={t`Liquidity`}
                    isSortActive={sortMethod === 'liquidity'}
                    isSortDescending={isSortDescending}
                    isNumeric
                    onClick={() => onHeaderClick('liquidity')}
                  />
                  <SortableTableHeader
                    name={t`Market Cap`}
                    isSortActive={sortMethod === 'market_cap'}
                    isSortDescending={isSortDescending}
                    isNumeric
                    onClick={() => onHeaderClick('market_cap')}
                    textAlign="right"
                  />
                </Tr>
              </Thead>

              <Tbody>
                {markets.map((market, i) => {
                  return (
                    <LinkBox
                      key={i}
                      as={Tr}
                      transform="scale(1)"
                      cursor="pointer"
                      role="group"
                      _hover={{ bg: 'bgSecondary' }}
                      onMouseEnter={() => setIsHoveringRow(true)}
                      onMouseLeave={() => setIsHoveringRow(false)}
                    >
                      <Td>
                        <LinkOverlay
                          aria-label={`Link to token page for ${market.baseToken.symbol}`}
                          href={`/${market.chain}/${market.marketAddress}`}
                        />
                        <HStack>
                          <ChainIcon chain={market.chain} size="20px" />
                          <Text>{market.baseToken.symbol}</Text>
                          <Text textColor="textSecondary" ml={-1}>
                            / {market.quoteToken.symbol}
                          </Text>
                          <Image
                            src={market.logoUrl}
                            boxSize={6}
                            objectFit="contain"
                            ml={2}
                          />
                          <Text>{market.baseToken.name}</Text>
                        </HStack>
                      </Td>
                      <Td>
                        <HStack justify="flex-end">
                          <Text textColor="textSecondary">
                            {formattedNum(market.baseToken.priceUsd, {
                              places: 5,
                              usd: true
                            })}
                          </Text>
                          <Text>
                            {formattedNum(market.baseToken.priceNative, {
                              places: 5
                            })}{' '}
                            {market.quoteToken.symbol}
                          </Text>
                        </HStack>
                      </Td>
                      <Td textAlign="right">
                        {market.baseTokenPctChange >= 0 ? (
                          <Text textColor="candleGreen">
                            +
                            {formattedNum(market.baseTokenPctChange, {
                              places: 2
                            })}
                            %
                          </Text>
                        ) : (
                          <Text textColor="candleRed">
                            {formattedNum(market.baseTokenPctChange, {
                              places: 2
                            })}
                            %
                          </Text>
                        )}
                      </Td>
                      <Td textAlign="right">
                        {formatShortDistanceToNow(market.createdAt)}
                      </Td>
                      <Td textAlign="right">
                        {formattedNum(market.volumeUsd, {
                          places: 5,
                          usd: true
                        })}
                      </Td>
                      <Td textAlign="right">
                        {formattedNum(market.liquidityUsd, {
                          places: 5,
                          usd: true
                        })}
                      </Td>
                      <Td textAlign="right">
                        {formattedNum(market.circulatingSupplyUsd, {
                          places: 5,
                          usd: true
                        })}
                      </Td>
                    </LinkBox>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </VStack>

      {hasNextMarketsPage && !isLoadingMarkets && (
        <Center mt={4}>
          <Button
            variant="boxShadowFlat"
            bg="bgSecondary"
            color="textPrimary"
            isLoading={isFetchingNextMarketsPage}
            onClick={() => fetchNextMarkets()}
          >
            Load More
          </Button>
        </Center>
      )}
    </Box>
  )
}

export default TokenMill
