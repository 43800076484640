import useCreateMarketAndTokenEVM from 'hooks/evm/useCreateMarketAndToken'
import useCreateTokenMarketSolana from 'solana/hooks/useCreateMarketAndToken'
import { Chain } from 'types/dexbarn'

export interface VestingArgs {
  baseAmount: bigint
  cliffDuration: number
  percentageAmountBps: number
  quoteAmount: bigint
  start: number
  vestingDuration: number
}

export interface CreateMarketAndTokenArgs {
  askPrices: number[]
  bidPrices: number[]
  chain: Chain
  name: string
  symbol: string
  creatorFeeShareBps?: number
  decimals?: number
  enabled?: boolean
  stakingFeeShareBps?: number
  totalSupply?: number
  vestingArgs?: VestingArgs
}

const useCreateMarketAndToken = (props: CreateMarketAndTokenArgs) => {
  const { chain } = props
  const isEVM = chain === 'avalanche'

  const createEVM = useCreateMarketAndTokenEVM({
    ...props,
    enabled: props.enabled && isEVM
  })

  const createSolana = useCreateTokenMarketSolana(props)

  switch (chain) {
    case 'avalanche':
      return createEVM
    case 'solana':
      return {
        createMarketAndTokenAsync: createSolana.mutateAsync,
        error: createSolana.error,
        isLoading: createSolana.isPending,
        isSimulating: false,
        marketAddress: createSolana.data?.marketAddress
      }
  }
}

export default useCreateMarketAndToken
