import { chains } from 'constants/chains'
import { useMemo } from 'react'
import { PortfolioToken } from 'types/portfolio'
import { useBalance } from 'wagmi'

const useUserOwnedTokensEVM = () => {
  const balanceHooks = chains.map((chain) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useBalance({ chainId: chain.id })
  })

  const isLoading = balanceHooks.some((balance) => balance.isLoading)

  const data: PortfolioToken[] = useMemo(() => {
    return balanceHooks.map((balance, index) => ({
      amount: Number(balance.data?.formatted) || 0,
      chain: chains[index].dexbarnId,
      priceUsd: 0,
      tokenAddress: undefined,
      tokenLogoURI: undefined,
      tokenSymbol: chains[index].nativeCurrency.symbol,
      valueUsd: 0
    }))
  }, [balanceHooks])

  return {
    data,
    isLoading
  }
}

export default useUserOwnedTokensEVM
