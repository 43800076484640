import {
  getTokenMetadata,
  TOKEN_2022_PROGRAM_ID,
  TOKEN_PROGRAM_ID
} from '@solana/spl-token'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { Connection, PublicKey } from '@solana/web3.js'
import { useQuery } from '@tanstack/react-query'
import { Chain } from 'types/dexbarn'
import { PortfolioToken } from 'types/portfolio'

async function fetchSolanaTokens(
  connection: Connection,
  publicKey: PublicKey | null
): Promise<PortfolioToken[]> {
  if (!publicKey) {
    return []
  }

  const [nativeBalance, tokenAccounts, tokenAccounts2022] = await Promise.all([
    connection.getBalance(publicKey),
    connection.getParsedTokenAccountsByOwner(publicKey, {
      programId: TOKEN_PROGRAM_ID
    }),
    connection.getParsedTokenAccountsByOwner(publicKey, {
      programId: TOKEN_2022_PROGRAM_ID
    })
  ])

  const fetchTokenMetadata = async (
    mint: string
  ): Promise<{ symbol: string; uri: string } | null> => {
    try {
      const mintPublicKey = new PublicKey(mint)
      const metadata = await getTokenMetadata(connection, mintPublicKey)
      if (!metadata) {
        return null
      }
      return {
        symbol: metadata.symbol,
        uri: metadata.uri
      }
    } catch (error) {
      console.error(`Error fetching metadata for token ${mint}:`, error)
      return null
    }
  }

  const tokenPromises = [
    ...tokenAccounts.value,
    ...tokenAccounts2022.value
  ].map(async (account) => {
    const parsedInfo = account.account.data.parsed.info
    const metadata = await fetchTokenMetadata(parsedInfo.mint)
    return {
      amount: parsedInfo.tokenAmount.uiAmount as number,
      chain: 'solana' as Chain,
      priceUsd: 0,
      tokenAddress: parsedInfo.mint as string,
      tokenLogoURI: metadata?.uri,
      tokenSymbol: metadata?.symbol || 'Unknown',
      valueUsd: 0
    }
  })

  const tokens = await Promise.all(tokenPromises)

  return [
    {
      amount: nativeBalance / 1e9,
      chain: 'solana',
      priceUsd: 0,
      tokenAddress: undefined,
      tokenLogoURI: undefined,
      tokenSymbol: 'SOL',
      valueUsd: 0
    },
    ...tokens
  ]
}

function useUserOwnedTokens() {
  const { connection } = useConnection()
  const { publicKey } = useWallet()

  return useQuery<PortfolioToken[], Error>({
    enabled: !!publicKey,
    queryFn: () => fetchSolanaTokens(connection, publicKey),
    queryKey: ['solanaTokens', publicKey?.toBase58()]
  })
}

export default useUserOwnedTokens
