import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  LinkBox,
  LinkOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import useGetMarketRecentActivity from 'hooks/tokenmill/useGetMarketRecentActivity'
import useLiveTrades from 'hooks/tokenmill/useLiveTrades'
import LiveIndicator from 'pages/TokenMill/LiveIndicator'
import React, { useMemo, useState } from 'react'
import { Chain } from 'types/dexbarn'
import { shortenAddress } from 'utils/addresses'
import { getBlockExplorer } from 'utils/chains'
import { formatShortDistanceToNow, formattedNum } from 'utils/format'

interface TokenMillRecentTradesProps {
  baseTokenSymbol: string
  chain: Chain
  marketAddress: string
}

const TokenMillRecentTrades = ({
  baseTokenSymbol,
  chain,
  marketAddress
}: TokenMillRecentTradesProps) => {
  const [isPaused, setIsPaused] = useState<boolean>(false)
  const [isHoveringRow, setIsHoveringRow] = useState<boolean>(false)
  const { data: liveTrades, pausedDataCount } = useLiveTrades({
    baseTokenSymbol,
    chain,
    isPaused: isPaused || isHoveringRow,
    marketAddress
  })

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useGetMarketRecentActivity({
      address: marketAddress,
      chain,
      pageSize: 10
    })

  const trades = useMemo(() => {
    const recentActivities = data?.pages.flatMap((page) => page) || []
    return [
      ...liveTrades,
      ...recentActivities.map((activity) => ({
        ...activity,
        baseTokenPriceUsd:
          activity.orderType === 'ask'
            ? activity.tokenIn.priceUsd
            : activity.tokenOut.priceUsd
      }))
    ]
  }, [liveTrades, data?.pages])

  return (
    <Box
      w={{ base: '100vw', md: 'auto' }}
      pb={{ base: 4, md: 6 }}
      px={{ base: 0, md: 2 }}
    >
      <HStack
        pt={{ base: 4, md: 6 }}
        px={4}
        pb={2}
        justify="space-between"
        w="full"
      >
        <Heading size="sm">Recent Trades</Heading>

        <LiveIndicator
          isPaused={isPaused || isHoveringRow}
          onTogglePause={() => setIsPaused(!isPaused)}
          pausedDataCount={pausedDataCount}
        />
      </HStack>

      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <TableContainer>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th py={4}>Date</Th>
                <Th py={4}>Type</Th>
                <Th py={4}>Maker</Th>
                <Th py={4} isNumeric>
                  USD
                </Th>
                <Th py={4} isNumeric>
                  Amount In
                </Th>
                <Th py={4} isNumeric>
                  Amount Out
                </Th>
                <Th py={4} isNumeric>
                  Price
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {trades.map((activity, index) => {
                const totalTradeUsd =
                  Number(activity.amountIn) * activity.tokenIn.priceUsd

                return (
                  <LinkBox
                    as={Tr}
                    key={index}
                    cursor="pointer"
                    transform="scale(1)"
                    role="group"
                    _hover={{ bg: 'hover' }}
                    onMouseEnter={() => setIsHoveringRow(true)}
                    onMouseLeave={() => setIsHoveringRow(false)}
                  >
                    <Td>
                      <LinkOverlay
                        isExternal
                        aria-label="link to transaction on explorer"
                        href={
                          getBlockExplorer(activity.txnHash, chain, 'tx').url
                        }
                      />
                      <Text>
                        {formatShortDistanceToNow(new Date(activity.timestamp))}{' '}
                        ago
                      </Text>
                    </Td>
                    <Td>
                      <Text
                        textColor={
                          activity.orderType === 'ask'
                            ? 'candleRed'
                            : 'candleGreen'
                        }
                      >
                        {activity.orderType === 'ask' ? 'SELL' : 'BUY'}
                      </Text>
                    </Td>
                    <Td>{shortenAddress(activity.userAddress, 2)}</Td>
                    <Td isNumeric>
                      {formattedNum(totalTradeUsd, { places: 5, usd: true })}
                    </Td>
                    <Td isNumeric>
                      {formattedNum(activity.amountIn, { places: 5 })}{' '}
                      {activity.tokenIn.symbol}
                    </Td>
                    <Td isNumeric>
                      {formattedNum(activity.amountOut, { places: 5 })}{' '}
                      {activity.tokenOut.symbol}
                    </Td>
                    <Td isNumeric>
                      {formattedNum(activity.baseTokenPriceUsd, {
                        allowSmallDecimals: true,
                        usd: true
                      })}
                    </Td>

                    <Td>
                      <ExternalLinkIcon mx="2px" />
                    </Td>
                  </LinkBox>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      {hasNextPage ? (
        <Center w="full" mt={4}>
          <Button
            variant="boxShadowFlat"
            bg="bgSecondary"
            color="textPrimary"
            isLoading={isFetchingNextPage}
            onClick={() => fetchNextPage()}
          >
            Load More
          </Button>
        </Center>
      ) : null}
    </Box>
  )
}

export default TokenMillRecentTrades
