import useAddErrorPopup from 'hooks/useAddErrorPopup'
import { useDexbarnClient } from 'hooks/useDexbarn'
import { useCallback } from 'react'
import { AuthTokens } from 'types/dexbarn'

const useLogin = (): ((
  address: string,
  signature: string,
  message: string
) => Promise<AuthTokens>) => {
  const client = useDexbarnClient()
  const addErrorPopup = useAddErrorPopup()

  return useCallback(
    async (
      address: string,
      signature: string,
      message: string
    ): Promise<AuthTokens> => {
      try {
        const response = await client.post<AuthTokens>(`/v1/auth/login`, {
          address,
          message,
          signature
        })
        return response.data
      } catch (e) {
        if (e instanceof Error) {
          addErrorPopup({
            subtitle: e.message,
            summary: 'Unable to authenticate'
          })
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [client, addErrorPopup]
  )
}

export default useLogin
