import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { Connection, PublicKey } from '@solana/web3.js'
import { useQuery } from '@tanstack/react-query'
import { formatUnits } from 'viem'

interface TokenBalance {
  decimals: number
  formatted: string
  value: bigint
}

async function getTokenBalance(
  connection: Connection,
  ownerPublicKey: PublicKey
): Promise<TokenBalance> {
  try {
    const balance = await connection.getBalance(ownerPublicKey)
    const decimals = 9

    const value = BigInt(balance)

    return {
      decimals,
      formatted: formatUnits(value, decimals),
      value
    }
  } catch (error) {
    console.error('Error fetching token balance:', error)
    throw error
  }
}

interface UseSolBalanceProps {
  enabled?: boolean
}

const useSolBalance = ({ enabled }: UseSolBalanceProps = { enabled: true }) => {
  const { connection } = useConnection()
  const { publicKey } = useWallet()

  return useQuery<TokenBalance, Error>({
    enabled: enabled && !!connection && !!publicKey,
    queryFn: () => getTokenBalance(connection, publicKey!),
    queryKey: ['solanaNativeTokenBalance', publicKey?.toBase58()]
  })
}

export default useSolBalance
