import { Idl, Program } from '@coral-xyz/anchor'
import * as spl from '@solana/spl-token'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { PublicKey, Transaction } from '@solana/web3.js'
import { useMutation } from '@tanstack/react-query'
import { UseClaimStakingRewardsProps } from 'hooks/tokenmill/useClaimStakingRewards'
import useTransactionToast from 'hooks/useTransactionToast'
import { useCallback } from 'react'
import TokenMillIdl from 'solana/idl/token_mill.json'

const useClaimStakingRewards = ({
  marketAddress,
  onSuccess,
  quoteTokenAddress
}: UseClaimStakingRewardsProps) => {
  const { connection } = useConnection()
  const wallet = useWallet()
  const addTransactionToast = useTransactionToast()

  const claimPendingRewards = useCallback(async () => {
    if (!wallet.publicKey) throw new Error('Wallet not connected')

    const program = new Program(TokenMillIdl as Idl, { connection })
    const market = new PublicKey(marketAddress)
    const quoteTokenMint = new PublicKey(quoteTokenAddress)

    const [staking] = PublicKey.findProgramAddressSync(
      [Buffer.from('market_staking'), market.toBuffer()],
      program.programId
    )

    const [stakePosition] = PublicKey.findProgramAddressSync(
      [
        Buffer.from('stake_position'),
        market.toBuffer(),
        wallet.publicKey.toBuffer()
      ],
      program.programId
    )

    const transaction = new Transaction()

    const marketQuoteTokenAta = spl.getAssociatedTokenAddressSync(
      quoteTokenMint,
      market,
      true
    )

    const userQuoteTokenAta = spl.getAssociatedTokenAddressSync(
      quoteTokenMint,
      wallet.publicKey
    )

    const userBaseTokenAtaInfo =
      await connection.getAccountInfo(userQuoteTokenAta)

    if (!userBaseTokenAtaInfo) {
      const createUserAtaInstruction =
        spl.createAssociatedTokenAccountInstruction(
          wallet.publicKey,
          userQuoteTokenAta,
          wallet.publicKey,
          quoteTokenMint,
          spl.TOKEN_PROGRAM_ID
        )
      transaction.add(createUserAtaInstruction)
    }

    const claimStakingRewardsIx = await program.methods
      .claimStakingRewards()
      .accountsPartial({
        market,
        marketQuoteTokenAta,
        quoteTokenMint,
        quoteTokenProgram: spl.TOKEN_PROGRAM_ID,
        stakePosition,
        staking,
        user: wallet.publicKey,
        userQuoteTokenAta
      })
      .instruction()
    transaction.add(claimStakingRewardsIx)

    // Unwrap wSOL if it's the quote token
    if (quoteTokenAddress === spl.NATIVE_MINT.toBase58()) {
      const closeAccountIx = spl.createCloseAccountInstruction(
        userQuoteTokenAta,
        wallet.publicKey,
        wallet.publicKey,
        [],
        spl.TOKEN_PROGRAM_ID
      )
      transaction.add(closeAccountIx)
    }

    const signature = await wallet.sendTransaction(transaction, connection)

    await connection.confirmTransaction(signature, 'confirmed')

    addTransactionToast({
      chain: 'solana',
      description: 'Claimed staking rewards',
      hash: signature,
      walletAddress: wallet.publicKey.toBase58()
    })

    return signature
  }, [
    wallet,
    connection,
    marketAddress,
    quoteTokenAddress,
    addTransactionToast
  ])

  const { isPending, mutateAsync } = useMutation({
    mutationFn: claimPendingRewards,
    onError: (error) => {
      console.error('Error claiming rewards:', error)
    },
    onSuccess
  })

  return {
    claimStakingRewardsAsync: mutateAsync,
    isClaimingStakingRewards: isPending
  }
}

export default useClaimStakingRewards
