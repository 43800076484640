import { BN, Idl, Program } from '@coral-xyz/anchor'
import * as spl from '@solana/spl-token'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import {
  Connection,
  PublicKey,
  SystemProgram,
  Transaction
} from '@solana/web3.js'
import { useQuery } from '@tanstack/react-query'
import { TM_CONFIG, TM_PROTOCOL_FEE_RECIPIENT } from 'solana/constants'
import TokenMillIdl from 'solana/idl/token_mill.json'

interface UseSimulateSwapProps {
  marketAddress: string
  swapType: 'buy' | 'sell'
  amountIn?: bigint
  baseTokenAddress?: string
  enabled?: boolean
  quoteTokenAddress?: string
}

export const getSwapTransaction = async ({
  amountIn,
  baseTokenAddress,
  connection,
  isSimulation,
  marketAddress,
  minAmountOut,
  quoteTokenAddress,
  referrerAddress,
  swapType,
  walletPublicKey
}: {
  connection: Connection
  marketAddress: string
  swapType: 'buy' | 'sell'
  amountIn?: bigint
  baseTokenAddress?: string
  isSimulation?: boolean
  minAmountOut?: bigint
  quoteTokenAddress?: string
  referrerAddress?: string
  walletPublicKey?: PublicKey
}) => {
  if (!walletPublicKey) {
    throw new Error('Wallet not connected')
  }

  if (!baseTokenAddress || !quoteTokenAddress) {
    throw new Error('Base and quote token addresses required')
  }

  if (!amountIn) {
    throw new Error('Swap amount required')
  }

  const program = new Program(TokenMillIdl as Idl, { connection })

  const market = new PublicKey(marketAddress)
  const baseToken = new PublicKey(baseTokenAddress)
  const quoteToken = new PublicKey(quoteTokenAddress)
  const referrer = referrerAddress ? new PublicKey(referrerAddress) : undefined

  const marketBaseTokenATA = spl.getAssociatedTokenAddressSync(
    baseToken,
    market,
    true,
    spl.TOKEN_2022_PROGRAM_ID
  )
  const userBaseTokenATA = spl.getAssociatedTokenAddressSync(
    baseToken,
    walletPublicKey,
    true,
    spl.TOKEN_2022_PROGRAM_ID
  )
  const marketQuoteTokenATA = spl.getAssociatedTokenAddressSync(
    quoteToken,
    market,
    true
  )
  const userQuoteTokenATA = spl.getAssociatedTokenAddressSync(
    quoteToken,
    walletPublicKey
  )
  const protocolQuoteTokenATA = spl.getAssociatedTokenAddressSync(
    quoteToken,
    TM_PROTOCOL_FEE_RECIPIENT
  )

  let swapAction
  switch (swapType) {
    case 'buy':
      // buy <swapAmount> SOL of base token
      swapAction = [
        { buy: {} },
        { exactInput: {} },
        new BN(amountIn),
        minAmountOut ? new BN(minAmountOut) : new BN(0)
      ]
      break
    case 'sell':
      // sell <swapAmount> base token for SOL
      swapAction = [
        { sell: {} },
        { exactInput: {} },
        new BN(amountIn),
        minAmountOut ? new BN(minAmountOut) : new BN(0)
      ]
      break
  }

  const transaction = new Transaction()

  let referralAccountATA: PublicKey | undefined
  if (referrer) {
    const referrerReferralAccount = PublicKey.findProgramAddressSync(
      [Buffer.from('referral'), TM_CONFIG.toBuffer(), referrer.toBuffer()],
      program.programId
    )[0]

    referralAccountATA = spl.getAssociatedTokenAddressSync(
      quoteToken,
      referrerReferralAccount,
      true
    )

    const referrerAccountAtaInfo =
      await connection.getAccountInfo(referralAccountATA)

    if (!referrerAccountAtaInfo) {
      const createReferralAccountIx =
        spl.createAssociatedTokenAccountInstruction(
          walletPublicKey,
          referralAccountATA,
          referrerReferralAccount,
          quoteToken
        )

      transaction.add(createReferralAccountIx)
    }
  }

  const [userBaseTokenATAInfo, userQuoteTokenATAInfo] = await Promise.all([
    connection.getAccountInfo(userBaseTokenATA),
    connection.getAccountInfo(userQuoteTokenATA)
  ])

  if (!userBaseTokenATAInfo) {
    transaction.add(
      spl.createAssociatedTokenAccountInstruction(
        walletPublicKey,
        userBaseTokenATA,
        walletPublicKey,
        baseToken,
        spl.TOKEN_2022_PROGRAM_ID
      )
    )
  }

  if (!userQuoteTokenATAInfo) {
    transaction.add(
      spl.createAssociatedTokenAccountInstruction(
        walletPublicKey,
        userQuoteTokenATA,
        walletPublicKey,
        quoteToken
      )
    )
  }

  // Wrap SOL if necessary
  if (quoteToken.equals(spl.NATIVE_MINT) && swapType === 'buy') {
    // Transfer SOL into the wrapped SOL token account
    const transferIx = SystemProgram.transfer({
      fromPubkey: walletPublicKey,
      lamports: amountIn,
      toPubkey: userQuoteTokenATA
    })

    // Sync the wrapped SOL token account
    const syncIx = spl.createSyncNativeInstruction(
      userQuoteTokenATA,
      spl.TOKEN_PROGRAM_ID
    )

    transaction.add(transferIx)
    transaction.add(syncIx)
  }

  const swapInstruction = await program.methods
    .swap(...swapAction)
    .accounts({
      baseTokenMint: baseToken,
      baseTokenProgram: spl.TOKEN_2022_PROGRAM_ID,
      config: TM_CONFIG,
      market,
      marketBaseTokenAta: marketBaseTokenATA,
      marketQuoteTokenAta: marketQuoteTokenATA,
      protocolQuoteTokenAta: protocolQuoteTokenATA,
      quoteTokenMint: quoteToken,
      quoteTokenProgram: spl.TOKEN_PROGRAM_ID,
      referralTokenAccount: referralAccountATA || program.programId,
      user: walletPublicKey,
      userBaseTokenAta: userBaseTokenATA,
      userQuoteTokenAta: userQuoteTokenATA
    })
    .instruction()

  transaction.add(swapInstruction)

  // Unwrap SOL if it's a sell and the quote token is SOL
  if (
    quoteToken.equals(spl.NATIVE_MINT) &&
    swapType === 'sell' &&
    !isSimulation
  ) {
    const closeAccountIx = spl.createCloseAccountInstruction(
      userQuoteTokenATA,
      walletPublicKey,
      walletPublicKey,
      [],
      spl.TOKEN_PROGRAM_ID
    )
    transaction.add(closeAccountIx)
  }

  transaction.feePayer = walletPublicKey

  return transaction
}

const useSimulateSwap = ({
  amountIn,
  baseTokenAddress,
  enabled,
  marketAddress,
  quoteTokenAddress,
  swapType
}: UseSimulateSwapProps) => {
  const { connection } = useConnection()
  const { wallet } = useWallet()

  const simulateSwap = async () => {
    if (!wallet || !wallet.adapter.publicKey) {
      throw new Error('Wallet not connected')
    }

    if (!baseTokenAddress || !quoteTokenAddress) {
      throw new Error('Base and quote token addresses required')
    }

    if (!amountIn) {
      throw new Error('Swap amount required')
    }

    const transaction = await getSwapTransaction({
      amountIn,
      baseTokenAddress,
      connection,
      isSimulation: true,
      marketAddress,
      quoteTokenAddress,
      swapType,
      walletPublicKey: wallet.adapter.publicKey
    })

    const result = await connection.simulateTransaction(transaction)
    const returnData = result.value.returnData?.data
    if (!returnData) {
      throw new Error('Cannot get return data from simulation')
    }

    const buffer = Buffer.from(returnData[0], returnData[1])
    const baseAmount = buffer.readBigUInt64LE(0)
    const quoteAmount = buffer.readBigUInt64LE(8)

    let simulatedAmountOut: bigint
    switch (swapType) {
      case 'buy':
        simulatedAmountOut = baseAmount
        break
      case 'sell':
        simulatedAmountOut = quoteAmount
    }

    return {
      simulatedAmountOut
    }
  }

  return useQuery({
    enabled:
      enabled &&
      !!wallet?.adapter.publicKey &&
      !!baseTokenAddress &&
      !!amountIn,
    queryFn: simulateSwap,
    queryKey: [
      'swapSimulation',
      marketAddress,
      baseTokenAddress,
      quoteTokenAddress,
      amountIn?.toString(),
      swapType,
      wallet?.adapter.publicKey?.toBase58()
    ]
  })
}

export default useSimulateSwap
