import { UseReleaseTokensProps } from 'hooks/tokenmill/useReleaseTokens'

const useReleaseTokensSolana = (props: UseReleaseTokensProps) => {
  console.log('release tokens solana', props)

  return {
    isReleasing: false,
    releaseTokensAsync: async () => {}
  }
}

export default useReleaseTokensSolana
