import { createAction } from '@reduxjs/toolkit'

export const setAreTermsAccepted = createAction<boolean>(
  'settings/areTermsAccepted'
)

export const setOriginCountryCode = createAction<string>(
  'settings/originCountryCode'
)

export const setIsUKDisclaimerHidden = createAction<boolean>(
  'settings/isUKDisclaimerHidden'
)
