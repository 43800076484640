import { CNATIVE, WNATIVE } from 'constants/token'
import { useMemo } from 'react'
import { Market } from 'types/market'
import { getChainId } from 'utils/chains'

import useGetMarketsFromBarn, {
  UseGetMarketsParams
} from './useGetMarketsFromBarn'
import useLiveMarkets from './useLiveMarkets'

const useGetTMMarkets = (
  params: {
    isPaused: boolean
  } & UseGetMarketsParams
) => {
  const chainId = getChainId(params.chain)

  const nativeCurrency = CNATIVE[chainId]
  const wrappedNativeToken = WNATIVE[chainId]

  // fetch data from barn
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isLoadingDexbarnMarkets
  } = useGetMarketsFromBarn(params)
  const dexbarnMarkets = useMemo(
    () => data?.pages.flatMap((page) => page) || [],
    [data]
  )

  // live markets
  const { data: liveMarkets = [], pausedMarketsCount } = useLiveMarkets({
    chain: params.chain,
    isPaused: params.isPaused
  })

  // process data
  const markets = useMemo(() => {
    if (dexbarnMarkets.length === 0) return []

    const processedMarkets: Market[] = liveMarkets

    processedMarkets.push(
      ...dexbarnMarkets.map((market) => {
        return {
          baseToken: market.baseToken,
          baseTokenPctChange: market.baseTokenPctChange,
          chain: params.chain,
          circulatingSupply: market.circulatingSupply,
          circulatingSupplyUsd: market.baseMarketCapUsd,
          createdAt: new Date(market.marketCreatedAt),
          description: market.description,
          liquidityUsd: market.quoteLiquidityUsd,
          logoUrl: market.iconUrl,
          marketAddress: market.marketAddress,
          quoteToken: {
            address: market.quoteToken.address,
            decimals: market.quoteToken.decimals,
            name: market.quoteToken.name,
            priceNative: market.quoteToken.priceNative,
            priceUsd: market.quoteToken.priceUsd,
            symbol:
              market.quoteToken.address.toLowerCase() ===
              wrappedNativeToken.address.toLowerCase()
                ? nativeCurrency.symbol
                : market.quoteToken.symbol
          },
          volumeUsd: market.volumeUsd
        }
      })
    )

    return processedMarkets
  }, [
    nativeCurrency,
    wrappedNativeToken,
    dexbarnMarkets,
    params.chain,
    liveMarkets
  ])

  return {
    data: markets,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isLoadingDexbarnMarkets,
    pausedMarketsCount
  }
}

export default useGetTMMarkets
