import {
  Box,
  Divider,
  Heading,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import ConnectWalletPlaceholder from 'components/ConnectWalletPlaceholder'
import CurrencyLogo from 'components/CurrencyLogo'
import useUserOwnedTokensEVM from 'hooks/evm/useUserOwnedTokensEVM'
import useAccountOnChain from 'hooks/tokenmill/useAccountOnChain'
import React from 'react'
import useUserOwnedTokensSolana from 'solana/hooks/useUserOwnedTokens'
import { Chain } from 'types/dexbarn'
import { formattedNum } from 'utils/format'

type PortfolioToken = {
  amount: number
  chain: Chain
  priceUsd: number
  tokenAddress: string | undefined
  tokenSymbol: string
  valueUsd: number
}

const Portfolio = () => {
  const { address: addressEVM } = useAccountOnChain('avalanche')
  const { address: addressSolana } = useAccountOnChain('solana')

  const { data: userTokensSolana = [] } = useUserOwnedTokensSolana()
  const { data: userTokensEVM = [] } = useUserOwnedTokensEVM()

  const tokens = [...userTokensEVM, ...userTokensSolana]

  const totalWalletValueUsd = tokens.reduce(
    (acc, token) => acc + token.valueUsd,
    0
  )

  const stakedTokens: PortfolioToken[] = [
    {
      amount: 10_000_000,
      chain: 'avalanche',
      priceUsd: 0.00039432,
      tokenAddress: '0x420fca0121dc28039145009570975747295f2329',
      tokenSymbol: 'COQ',
      valueUsd: 393.42
    }
  ]

  const totalStakingValueUsd = stakedTokens.reduce(
    (acc, token) => acc + token.valueUsd,
    0
  )

  if (!addressEVM && !addressSolana) {
    return (
      <ConnectWalletPlaceholder
        title="Portfolio"
        description="Connect your wallet to view your portfolio"
      />
    )
  }

  return (
    <Box maxW="1600px" margin="0 auto" minH="90svh">
      <Box p={{ base: 4, md: 6 }}>
        <Heading size="sm">Portfolio</Heading>
      </Box>

      <Divider />

      <Box p={{ base: 4, md: 6 }}>
        <HStack justify="space-between">
          <Heading size="sm">Wallet</Heading>
          <Text fontWeight="semibold">
            {formattedNum(totalWalletValueUsd, { usd: true })}
          </Text>
        </HStack>

        <TableContainer w="full" pt={4}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th pl={0}>Token</Th>
                <Th textAlign="right">Price</Th>
                <Th textAlign="right">Amount</Th>
                <Th pr={0} textAlign="right">
                  USD Value
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {tokens.map((token) => {
                return (
                  <Tr key={token.tokenAddress + token.tokenSymbol}>
                    <Td pl={0}>
                      <HStack>
                        <CurrencyLogo
                          logoURI={token.tokenLogoURI}
                          boxSize={6}
                          address={token.tokenAddress}
                          symbol={token.tokenSymbol}
                          chain={token.chain}
                        />
                        <Text fontWeight="semibold">{token.tokenSymbol}</Text>
                      </HStack>
                    </Td>
                    <Td isNumeric>
                      {formattedNum(token.priceUsd, { usd: true })}
                    </Td>
                    <Td isNumeric>
                      {formattedNum(token.amount, { places: 5 })}
                    </Td>
                    <Td pr={0} isNumeric>
                      {formattedNum(token.valueUsd, { usd: true })}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      <Divider my={2} />

      <Box p={{ base: 4, md: 6 }}>
        <HStack justify="space-between">
          <Heading size="sm">Staking</Heading>
          <Text fontWeight="semibold">
            {formattedNum(totalStakingValueUsd, { usd: true })}
          </Text>
        </HStack>

        <TableContainer w="full" pt={4}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th pl={0}>Token</Th>
                <Th textAlign="right">Price</Th>
                <Th textAlign="right">Amount</Th>
                <Th pr={0} textAlign="right">
                  USD Value
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {stakedTokens.map((token) => {
                return (
                  <Tr key={token.tokenAddress + token.tokenSymbol}>
                    <Td pl={0}>
                      <HStack>
                        <CurrencyLogo
                          boxSize={6}
                          address={token.tokenAddress}
                          symbol={token.tokenSymbol}
                          chain={token.chain}
                        />
                        <Text fontWeight="semibold">{token.tokenSymbol}</Text>
                      </HStack>
                    </Td>
                    <Td isNumeric>
                      {formattedNum(token.priceUsd, { usd: true })}
                    </Td>
                    <Td isNumeric>
                      {formattedNum(token.amount, { places: 5 })}
                    </Td>
                    <Td pr={0} isNumeric>
                      {formattedNum(token.valueUsd, { usd: true })}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default Portfolio
