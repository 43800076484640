import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import useAddErrorPopup from 'hooks/useAddErrorPopup'
import { useDexbarnClient } from 'hooks/useDexbarn'
import { AuthTokens } from 'types/dexbarn'
import { useAccount } from 'wagmi'

interface PatchReferralCodeArgs {
  referral_code: string
}

interface PatchReferralCodeResponse {
  code: string
  message: string
  user: string
}

interface PatchReferralCodeVariables {
  args: PatchReferralCodeArgs
  authTokens: AuthTokens
}

// TODO: support Solana
const usePatchReferralCode = (): UseMutationResult<
  PatchReferralCodeResponse,
  Error,
  PatchReferralCodeVariables
> => {
  const client = useDexbarnClient()
  const account = useAccount()
  const addErrorPopup = useAddErrorPopup()

  return useMutation<
    PatchReferralCodeResponse,
    Error,
    PatchReferralCodeVariables
  >({
    mutationFn: async ({ args, authTokens }) => {
      if (!account.address) {
        throw new Error('Wallet not connected')
      }

      const response = await client.patch<PatchReferralCodeResponse>(
        '/v1/users/referral-code',
        args,
        { headers: { Authorization: `Bearer ${authTokens.accessToken}` } }
      )
      return response.data
    },
    onError: (error) => {
      if (error instanceof AxiosError && error.response?.data.detail) {
        addErrorPopup({
          subtitle: error.response?.data.detail,
          summary: 'Unable to update referral code'
        })
      } else {
        addErrorPopup({
          subtitle: error.message,
          summary: 'Unable to update referral code'
        })
      }
      console.error(error)
    }
  })
}

export default usePatchReferralCode
