import useGetMarketEVM from 'hooks/evm/useGetMarketEVM'
import useGetMarketSolana from 'solana/hooks/useGetMarketSolana'
import { Chain } from 'types/dexbarn'

export interface UseGetMarketProps {
  chain: Chain
  marketAddress: string
}

const useGetMarket = ({ chain, marketAddress }: UseGetMarketProps) => {
  const resultEVM = useGetMarketEVM({ chain, marketAddress })
  const resultSolana = useGetMarketSolana({ chain, marketAddress })

  switch (chain) {
    case 'avalanche':
      return resultEVM
    case 'solana':
      return resultSolana
  }
}

export default useGetMarket
