import {
  Box,
  Center,
  Divider,
  Grid,
  Hide,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react'
import TradingViewChart from 'components/TradingViewChart'
import useAccountOnChain from 'hooks/tokenmill/useAccountOnChain'
import useGetMarket from 'hooks/tokenmill/useGetMarket'
import useTokenBalance from 'hooks/useTokenBalance'
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { Chain } from 'types/dexbarn'
import { isSolanaAddress } from 'utils/addresses'
import { isAddress } from 'viem'

import BondingCurvesChart from '../CreateToken/BondingCurvesChart'
import BalanceOverview from './BalanceOverview'
import CreatorFees from './CreatorFees'
import TokenMillDetailAnalytics from './TokenMillDetailAnalytics'
import TokenMillDetailHeader from './TokenMillDetailHeader'
import TokenMillRecentTrades from './TokenMillRecentTrades'
import TokenMillTradePanel from './TokenMillTradePanel'

interface TokenMillDetailProps {
  chain: Chain
  marketAddress: string
}

const TokenMillDetail = ({ chain, marketAddress }: TokenMillDetailProps) => {
  const {
    data: market,
    isLoading,
    refetch: refetchMarket
  } = useGetMarket({ chain, marketAddress })

  const {
    data: baseTokenBalance,
    isLoading: isLoadingBaseTokenBalance,
    refetch: refetchBaseTokenBalance
  } = useTokenBalance({
    chain,
    token: market?.baseToken.address
  })

  const account = useAccountOnChain(chain)
  const isCreator =
    market?.creator.toLowerCase() === account.address?.toLowerCase()

  const TradingViewChartMemo = React.memo(TradingViewChart)

  return (
    <Box maxW="1600px" margin="0 auto">
      <Grid
        templateColumns={{ base: '1fr', lg: 'auto 1px 500px' }}
        alignItems="flex-start"
      >
        <VStack spacing={0}>
          <TokenMillDetailHeader
            marketAddress={marketAddress}
            chain={chain}
            token={market?.baseToken}
            tokenLogoUrl={market?.baseTokenLogoUrl}
            twitterUrl={market?.twitterUrl}
            discordUrl={market?.discordUrl}
            telegramUrl={market?.telegramUrl}
            websiteUrl={market?.websiteUrl}
          />

          <Divider />

          {isLoading ? (
            <Center minH="300px">
              <Spinner />
            </Center>
          ) : market ? (
            <Tabs isLazy variant="bracketed" w="full" p={{ base: 4, md: 6 }}>
              <TabList>
                <Tab>Trading Chart</Tab>
                <Tab>Price Curve</Tab>
              </TabList>
              <TabPanels pt={4}>
                <TabPanel p={0}>
                  <TradingViewChartMemo
                    chain={chain}
                    tmMarketAddress={market.marketAddress}
                    inputCurrency={market.baseToken}
                    outputCurrency={market.quoteCurrency}
                    defaultResolution={'5'}
                    supportedResolutions={[
                      '5',
                      '15',
                      '60',
                      '240',
                      '720',
                      '1D',
                      '1W'
                    ]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <BondingCurvesChart
                    chain={chain}
                    askPrice={market.askPrice}
                    bidPrice={market.bidPrice}
                    circulatingSupply={market.circulatingSupply}
                    askPricePoints={market.askPricesPoints}
                    bidPricePoints={market.bidPricesPoints}
                    containerProps={{
                      height: { base: '400px', md: '500px' }
                    }}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          ) : null}

          <Divider />

          <TokenMillRecentTrades
            marketAddress={marketAddress}
            chain={chain}
            baseTokenSymbol={market?.baseToken.symbol || ''}
          />

          <Hide above="md">
            <Divider />
          </Hide>
        </VStack>

        <Hide below="md">
          <Divider orientation="vertical" h="full" />
        </Hide>

        {market && (
          <VStack spacing={0}>
            <TokenMillTradePanel
              chain={chain}
              baseToken={market.baseToken}
              baseTokenBalance={baseTokenBalance}
              onBaseTokenBalanceChange={refetchBaseTokenBalance}
              quoteCurrency={market.quoteCurrency}
              baseTokenLogoUrl={market.baseTokenLogoUrl}
              marketAddress={marketAddress}
              isLoadingBaseTokenBalance={isLoadingBaseTokenBalance}
            />

            <Divider />

            <BalanceOverview
              marketAddress={market.marketAddress}
              tokenSymbol={market.baseToken.symbol}
              tokenBalance={baseTokenBalance?.formatted}
              isLoadingTokenBalance={isLoadingBaseTokenBalance}
              chain={chain}
            />

            {isCreator ? (
              <>
                <Divider />
                <CreatorFees
                  marketAddress={marketAddress}
                  chain={chain}
                  quoteCurrency={market.quoteCurrency}
                  pendingCreatorFees={market.fees?.pendingCreatorFees}
                  onClaimSuccess={() => {
                    refetchMarket()
                  }}
                />
              </>
            ) : null}

            <Divider />

            <TokenMillDetailAnalytics market={market} />
          </VStack>
        )}
      </Grid>
    </Box>
  )
}

const TokenMillDetailWithRedirect = () => {
  const { chain: chainParam, marketAddress } = useParams()

  if (
    !marketAddress ||
    (chainParam !== 'avalanche' && chainParam !== 'solana')
  ) {
    return <Navigate to="/404" />
  }

  const chain = chainParam as Chain

  switch (chain) {
    case 'avalanche':
      if (!isAddress(marketAddress)) {
        return <Navigate to="/404" />
      }
      break
    case 'solana':
      if (!isSolanaAddress(marketAddress)) {
        return <Navigate to="/404" />
      }
      break
  }

  return <TokenMillDetail marketAddress={marketAddress} chain={chain} />
}

export default TokenMillDetailWithRedirect
