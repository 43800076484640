import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import {
  setAreTermsAccepted,
  setIsUKDisclaimerHidden,
  setOriginCountryCode
} from './actions'

const useAreTermsAccepted = () => {
  const dispatch = useAppDispatch()
  const areTermsAccepted = useAppSelector(
    (state) => state.settings.areTermsAccepted
  )
  const updateAreTermsAccepted = useCallback(
    (areAccepted: boolean) => {
      dispatch(setAreTermsAccepted(areAccepted))
    },
    [dispatch]
  )
  return { areTermsAccepted, updateAreTermsAccepted }
}

const useOriginCountry = () => {
  const dispatch = useAppDispatch()
  const originCountryCode = useAppSelector(
    (state) => state.settings.originCountryCode
  )
  const updateOriginCountryCode = useCallback(
    (originCountryCode: string) => {
      dispatch(setOriginCountryCode(originCountryCode))
    },
    [dispatch]
  )
  return { originCountryCode, updateOriginCountryCode }
}

const useSetUKDisclaimerHidden = () => {
  const dispatch = useAppDispatch()
  const isUKDisclaimerHidden = useAppSelector(
    (state) => state.settings.isUKDisclaimerHidden
  )
  const setUKDisclaimerHidden = useCallback(
    (isHidden: boolean) => {
      dispatch(setIsUKDisclaimerHidden(isHidden))
    },
    [dispatch]
  )
  return { isUKDisclaimerHidden, setUKDisclaimerHidden }
}

export { useAreTermsAccepted, useOriginCountry, useSetUKDisclaimerHidden }
