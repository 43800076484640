import { CNATIVE } from 'constants/token'
import useGetMarketFromBarn from 'hooks/barn/solana/useGetMarketFromBarn'
import { UseGetMarketProps } from 'hooks/tokenmill/useGetMarket'
import { useMemo } from 'react'
import { PricePoint } from 'utils/bondingCurves'
import { getChainId, getChainType } from 'utils/chains'
import { formatUnits } from 'viem'

import useMarketData from './useMarketData'

const useGetMarketSolana = ({ chain, marketAddress }: UseGetMarketProps) => {
  const chainId = getChainId(chain)
  const chainType = getChainType(chain)

  const { data: dexbarnMarket, isLoading: isLoadingDexbarnMarket } =
    useGetMarketFromBarn({
      chain,
      enabled: chainType === 'solana',
      marketAddress
    })

  const {
    data: marketData,
    isLoading: isLoadingMarketData,
    refetch: refetchMarketData
  } = useMarketData(marketAddress)

  const market = useMemo(() => {
    if (!dexbarnMarket || !marketData) return undefined

    const baseTokenDecimals = dexbarnMarket.baseToken.decimals

    const circulatingSupply = dexbarnMarket.circulatingSupply
    const circulatingSupplyUsd = dexbarnMarket?.baseMarketCapUsd

    const totalSupply = Number(
      formatUnits(marketData.totalSupply, baseTokenDecimals)
    )

    const askPrice = 0
    const bidPrice = 0

    const numPoints = marketData.askPrices.length
    const askPricesPoints: PricePoint[] = marketData.askPrices.map(
      (price, i) => ({
        price: Number(formatUnits(price, 10)),
        supply: (totalSupply / (numPoints - 1)) * i
      })
    )
    const bidPricesPoints: PricePoint[] = marketData.bidPrices.map(
      (price, i) => ({
        price: Number(formatUnits(price, 10)),
        supply: (totalSupply / (numPoints - 1)) * i
      })
    )

    return {
      askPrice,
      askPricesPoints,
      baseToken: {
        address: marketData.baseTokenMint.toBase58(),
        decimals: baseTokenDecimals,
        name: dexbarnMarket.baseToken.name,
        symbol: dexbarnMarket.baseToken.symbol
      },
      baseTokenLogoUrl: dexbarnMarket.iconUrl,
      baseTokenPriceNative: dexbarnMarket.baseToken.priceNative,
      baseTokenPriceUsd: dexbarnMarket.baseToken.priceUsd,
      bidPrice,
      bidPricesPoints,
      chain,
      circulatingSupply,
      createdAt: dexbarnMarket.marketCreatedAt,
      creator: marketData.creator.toBase58(),
      description: dexbarnMarket.description,
      discordUrl: dexbarnMarket.discordUrl,
      fees: marketData.fees,
      liquidityUsd: dexbarnMarket.quoteLiquidityUsd,
      marketAddress,
      marketCapUsd: circulatingSupplyUsd,
      quoteCurrency: {
        address: marketData.quoteTokenMint.toBase58(),
        decimals: marketData.quoteTokenDecimals,
        name: CNATIVE[chainId].name,
        symbol: CNATIVE[chainId].symbol
      },
      telegramUrl: dexbarnMarket.telegramUrl,
      totalSupply,
      twitterUrl: dexbarnMarket.twitterUrl,
      websiteUrl: dexbarnMarket.websiteUrl
    }
  }, [chainId, chain, marketAddress, dexbarnMarket, marketData])

  return {
    data: market,
    isLoading: isLoadingDexbarnMarket || isLoadingMarketData,
    refetch: () => {
      refetchMarketData()
    }
  }
}

export default useGetMarketSolana
