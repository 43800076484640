import { Button, Progress, Td, Text, Tr, VStack } from '@chakra-ui/react'
import { format } from 'date-fns'
import useAccountOnChain from 'hooks/tokenmill/useAccountOnChain'
import useReleaseTokens from 'hooks/tokenmill/useReleaseTokens'
import React from 'react'
import { Chain } from 'types/dexbarn'
import { VestingSchedule } from 'types/market'
import { shortenAddress } from 'utils/addresses'
import { formattedNum } from 'utils/format'

interface VestedtokenTableRowProps {
  baseTokenAddress: string
  chain: Chain
  onReleaseSuccess: () => void
  schedule: VestingSchedule
}

const VestedTokenTableRow = ({
  baseTokenAddress,
  chain,
  onReleaseSuccess,
  schedule
}: VestedtokenTableRowProps) => {
  const formatDate = (timestamp: number): string => {
    return format(new Date(timestamp * 1000), 'MMM dd, yyyy, hh:mm a')
  }

  const calculateUnlockRate = (schedule: VestingSchedule): string => {
    const tokensPerSecond =
      Number(schedule.total.formatted) / schedule.vestingDuration
    const tokensPerDay = tokensPerSecond * 86400
    return `${formattedNum(tokensPerDay)} / day`
  }

  const releasedPercentage =
    (Number(schedule.released.formatted) / Number(schedule.total.formatted)) *
    100
  const cliffEnd = schedule.start + schedule.cliffDuration
  const vestingEnd = schedule.start + schedule.vestingDuration

  const hasCliffStarted =
    schedule.start + schedule.cliffDuration <= Math.floor(Date.now() / 1000)

  const account = useAccountOnChain(chain)
  const isBeneficiary =
    schedule.beneficiary.toLowerCase() === account.address?.toLowerCase()

  const { isReleasing, releaseTokensAsync } = useReleaseTokens({
    baseTokenAddress,
    chain,
    enabled: isBeneficiary && hasCliffStarted,
    onReleaseSuccess,
    vestingPlanIndex: schedule.index
  })

  return (
    <Tr>
      <Td>
        <Text>{shortenAddress(schedule.beneficiary)}</Text>
      </Td>
      <Td>{`${formatDate(schedule.start)} - ${formatDate(vestingEnd)}`}</Td>
      <Td>{formatDate(cliffEnd)}</Td>
      <Td>{calculateUnlockRate(schedule)}</Td>
      <Td>{formattedNum(schedule.releasable.formatted, { places: 2 })}</Td>
      <Td>
        <VStack align="flex-start" spacing={1}>
          <Text>{`${formattedNum(schedule.released.formatted)} / ${formattedNum(
            schedule.total.formatted
          )}`}</Text>
          <Progress
            w="full"
            value={releasedPercentage}
            colorScheme="green"
            h="10px"
          />
        </VStack>
      </Td>
      <Td>
        <Button
          variant="boxShadowFlat"
          isLoading={isReleasing}
          onClick={releaseTokensAsync}
          isDisabled={!releaseTokensAsync}
          loadingText="Unlocking"
        >
          Unlock
        </Button>
      </Td>
    </Tr>
  )
}

export default VestedTokenTableRow
