import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react'
import React, { useCallback, useEffect } from 'react'
import { useLoginIfNeeded } from 'state/authentication/hooks'
import { AuthTokens, Chain } from 'types/dexbarn'

interface LoginModalProps {
  chain: Chain
  isOpen: boolean
  onClose: () => void
  onLoginSuccess: (authTokens: AuthTokens) => void
}

const LoginModal = ({
  chain,
  isOpen,
  onClose,
  onLoginSuccess
}: LoginModalProps) => {
  const { isPending, mutateAsync: loginIfNeeded } = useLoginIfNeeded({ chain })

  const onSignMessageClicked = useCallback(async () => {
    try {
      const authTokens = await loginIfNeeded()
      if (authTokens) {
        onLoginSuccess(authTokens)
        onClose()
      }
    } catch {}
  }, [loginIfNeeded, onLoginSuccess, onClose])

  useEffect(() => {
    if (isOpen) {
      onSignMessageClicked()
    }
  }, [isOpen, onSignMessageClicked])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="bgSecondary">
        <ModalHeader>Sign in to TokenMill</ModalHeader>
        <ModalCloseButton borderRadius={0} />
        <ModalBody>
          <Text>You need to be logged in to create a token.</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="boxShadowFlat"
            bg="accent.500"
            mr={3}
            onClick={onSignMessageClicked}
            isLoading={isPending}
            loadingText="Waiting for confirmation"
          >
            Sign Message
          </Button>
          <Button variant="boxShadowFlat" bg="transparent" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default LoginModal
