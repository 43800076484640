import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useDisclosure
} from '@chakra-ui/react'
import { useLingui } from '@lingui/react'
import {
  dynamicActivateLanguage,
  LANG_MAP,
  SUPPORTED_LANGUAGES
} from 'components/LanguageProvider'
import React from 'react'

const LanguageMenu = () => {
  const { i18n } = useLingui()
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <Menu isOpen={isOpen}>
      <MenuButton
        h="48px"
        minW="200px"
        as={Button}
        variant="boxShadowFlat"
        bg="bgSecondary"
        flexShrink={0}
        rightIcon={<ChevronDownIcon />}
        textAlign="left"
        onClick={onOpen}
      >
        {LANG_MAP[i18n.locale]}
      </MenuButton>
      <Portal>
        <MenuList zIndex="popover" onMouseLeave={onClose}>
          {SUPPORTED_LANGUAGES.map((language) => (
            <MenuItem
              key={language}
              h="48px"
              onClick={() => dynamicActivateLanguage(language)}
              fontWeight="bold"
            >
              {LANG_MAP[language]}
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  )
}

export default LanguageMenu
