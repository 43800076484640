import { Idl, Program } from '@coral-xyz/anchor'
import * as spl from '@solana/spl-token'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { PublicKey, Transaction } from '@solana/web3.js'
import { useMutation } from '@tanstack/react-query'
import { UseClaimReferrerFeesProps } from 'hooks/referrals/useClaimReferrerFees'
import useTransactionToast from 'hooks/useTransactionToast'
import { useCallback } from 'react'
import { TM_CONFIG } from 'solana/constants'
import TokenMillIdl from 'solana/idl/token_mill.json'

const useClaimReferrerFeesSolana = ({
  chain,
  onClaimSuccess,
  quoteTokenAddress
}: UseClaimReferrerFeesProps) => {
  const { connection } = useConnection()
  const wallet = useWallet()
  const addTransactionToast = useTransactionToast()

  const getClaimReferrerFeesTransaction = useCallback(async () => {
    if (!wallet.publicKey) throw new Error('Wallet not connected')

    const program = new Program(TokenMillIdl as Idl, {
      connection
    })

    const quoteTokenMint = new PublicKey(quoteTokenAddress)

    const userQuoteTokenATA = await spl.getAssociatedTokenAddress(
      quoteTokenMint,
      wallet.publicKey
    )

    const referralAccount = PublicKey.findProgramAddressSync(
      [
        Buffer.from('referral'),
        TM_CONFIG.toBuffer(),
        wallet.publicKey.toBuffer()
      ],
      program.programId
    )[0]

    const referralAccountATA = await spl.getAssociatedTokenAddress(
      quoteTokenMint,
      referralAccount,
      true
    )

    const [referralAccountInfo, userQuoteTokenInfo] = await Promise.all([
      connection.getAccountInfo(referralAccount),
      connection.getAccountInfo(userQuoteTokenATA)
    ])

    const transaction = new Transaction()

    console.log('referralAccountInfo', referralAccountInfo)

    if (!referralAccountInfo) {
      const createReferralAccountIx = await program.methods
        .createReferralAccount(wallet.publicKey)
        .accountsPartial({
          config: TM_CONFIG,
          referralAccount: referralAccount,
          user: wallet.publicKey
        })
        .instruction()

      transaction.add(createReferralAccountIx)
    }

    if (!userQuoteTokenInfo) {
      const createUserQuoteTokenATAIx =
        spl.createAssociatedTokenAccountInstruction(
          wallet.publicKey,
          userQuoteTokenATA,
          wallet.publicKey,
          quoteTokenMint,
          spl.TOKEN_PROGRAM_ID
        )

      transaction.add(createUserQuoteTokenATAIx)
    }

    const claimReferralFeesIx = await program.methods
      .claimReferralFees()
      .accountsPartial({
        program: program.programId,
        quoteTokenMint,
        quoteTokenProgram: spl.TOKEN_PROGRAM_ID,
        referralAccount,
        referralAccountQuoteTokenAta: referralAccountATA,
        referrer: wallet.publicKey,
        referrerQuoteTokenAta: userQuoteTokenATA
      })
      .instruction()

    transaction.add(claimReferralFeesIx)

    // Unwrap wSOL if the quote token is wSOL
    if (quoteTokenAddress === spl.NATIVE_MINT.toBase58()) {
      const closeAccountIx = spl.createCloseAccountInstruction(
        userQuoteTokenATA,
        wallet.publicKey,
        wallet.publicKey,
        [],
        spl.TOKEN_PROGRAM_ID
      )
      transaction.add(closeAccountIx)
    }

    return transaction
  }, [wallet.publicKey, quoteTokenAddress, connection])

  const {
    isPending: isClaiming,
    mutateAsync: claimAsync,
    reset: resetClaim
  } = useMutation({
    mutationFn: async () => {
      if (!wallet.publicKey) throw new Error('Wallet not connected')

      const transaction = await getClaimReferrerFeesTransaction()
      const signature = await wallet.sendTransaction(transaction, connection)

      addTransactionToast({
        chain,
        description: 'Claimed referrer fees',
        hash: signature,
        walletAddress: wallet.publicKey.toBase58()
      })

      const result = await connection.confirmTransaction(signature, 'processed')
      if (result.value.err) {
        throw new Error(result.value.err.toString())
      }

      return signature
    },
    onSuccess: onClaimSuccess
  })

  return {
    claimAsync,
    isClaiming,
    resetClaim
  }
}

export default useClaimReferrerFeesSolana
