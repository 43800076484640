import useTokenBalanceEVM from 'hooks/evm/useTokenBalance'
import useSolBalance from 'solana/hooks/useSolBalance'
import useTokenBalanceSolana from 'solana/hooks/useTokenBalance'
import { Chain } from 'types/dexbarn'

export interface UseTokenBalanceProps {
  chain: Chain
  enabled?: boolean
  token?: string | 'native'
}

const useTokenBalance = (props: UseTokenBalanceProps) => {
  const { chain, token } = props

  const resultEVM = useTokenBalanceEVM(props)
  const resultSolana = useTokenBalanceSolana(props)
  const resultSolanaNative = useSolBalance({
    enabled: chain === 'solana' && token === 'native'
  })

  switch (chain) {
    case 'avalanche':
      return resultEVM
    case 'solana':
      return token === 'native' ? resultSolanaNative : resultSolana
  }
}

export default useTokenBalance
