import { Box, Divider, VStack } from '@chakra-ui/react'
import useGetVestingData from 'hooks/evm/useGetVestingData'
import useGetMarket from 'hooks/tokenmill/useGetMarket'
import TokenMillDetailHeader from 'pages/TokenMillDetail/TokenMillDetailHeader'
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { Chain } from 'types/dexbarn'
import { isSolanaAddress } from 'utils/addresses'
import { isAddress } from 'viem'

import VestedTokensTable from './VestedTokensTable'

interface VestingProps {
  chain: Chain
  marketAddress: string
}

const Vesting = ({ chain, marketAddress }: VestingProps) => {
  const { data: market, isLoading: isLoadingMarket } = useGetMarket({
    chain,
    marketAddress
  })

  // TODO: get solana vesting data from API
  const {
    data: vestingData,
    isLoading: isLoadingVestingData,
    refetch: refetchVestingData
  } = useGetVestingData({
    baseTokenAddress: market?.baseToken.address,
    chain
  })

  return (
    <Box maxW="1600px" margin="0 auto" minH="90svh">
      <VStack spacing={0}>
        <TokenMillDetailHeader
          marketAddress={marketAddress}
          chain={chain}
          token={market?.baseToken}
          tokenLogoUrl={market?.baseTokenLogoUrl}
          twitterUrl={market?.twitterUrl}
          discordUrl={market?.discordUrl}
          telegramUrl={market?.telegramUrl}
          websiteUrl={market?.websiteUrl}
        />

        <Divider />

        <VestedTokensTable
          data={vestingData}
          isLoading={isLoadingMarket || isLoadingVestingData}
          chain={chain}
          baseTokenAddress={market?.baseToken.address}
          onReleaseSuccess={() => {
            refetchVestingData()
          }}
        />
      </VStack>

      <Divider />
    </Box>
  )
}

const VestingWithRedirect = () => {
  const { chain: chainParam, marketAddress } = useParams()

  if (
    !marketAddress ||
    (chainParam !== 'avalanche' && chainParam !== 'solana')
  ) {
    return <Navigate to="/404" />
  }

  const chain = chainParam as Chain

  switch (chain) {
    case 'avalanche':
      if (!isAddress(marketAddress)) {
        return <Navigate to="/404" />
      }
      break
    case 'solana':
      if (!isSolanaAddress(marketAddress)) {
        return <Navigate to="/404" />
      }
      break
  }

  return <Vesting marketAddress={marketAddress} chain={chain} />
}

export default VestingWithRedirect
