import {
  Button,
  Collapse,
  Input,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react'
import NumericalInput from 'components/NumericalInput'
import React, { useEffect, useState } from 'react'
import { parsePricePoints, PricePoint } from 'utils/bondingCurves'

const PricePointInput = ({
  onValueChange,
  point
}: {
  onValueChange: (value: string) => void
  point: number
}) => {
  const [inputValue, setInputValue] = useState<string>(point.toString())

  useEffect(() => {
    setInputValue(point.toString())
  }, [point])

  return (
    <NumericalInput
      inputType="decimal"
      value={inputValue}
      borderRadius="md"
      onValueChange={(value) => {
        setInputValue(value)

        const isValidNum = !isNaN(parseFloat(value))
        if (isValidNum) {
          onValueChange(value)
        }
      }}
    />
  )
}

interface PricePointsInputProps {
  pricePoints: PricePoint[]
  pricePointsError: string
  pricePointsString: string
  setPricePoints: (points: PricePoint[]) => void
  setPricePointsError: (error: string) => void
  setPricePointsString: (pricePointsString: string) => void
  title: string
  xMax: number
}

const PricePointsInput = ({
  pricePoints,
  pricePointsError,
  pricePointsString,
  setPricePoints,
  setPricePointsError,
  setPricePointsString,
  title,
  xMax
}: PricePointsInputProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  // update price points string
  const updatePricePointsFromString = (value: string) => {
    setPricePointsString(value)

    try {
      const points = parsePricePoints(value, xMax)
      setPricePoints(points)
      setPricePointsError('')
    } catch (error) {
      if (error instanceof Error) {
        setPricePointsError(error.message)
      } else {
        setPricePointsError('Invalid price points')
      }
    }
  }

  return (
    <VStack align="flex-start" w="full">
      <Text textColor="textPrimary" fontSize="sm">
        {title}
      </Text>
      <Input
        placeholder="0,1,2"
        fontWeight="semibold"
        value={pricePointsString}
        isInvalid={!!pricePointsError}
        onChange={(e) => {
          const val = e.currentTarget.value
          updatePricePointsFromString(val)
        }}
      />
      {pricePointsError && (
        <Text textColor="red.500" fontSize="sm">
          {pricePointsError}
        </Text>
      )}
      <Collapse in={isExpanded}>
        <SimpleGrid
          columns={{ base: 2, md: 4 }}
          w="full"
          gap={4}
          mt={2}
          mb={2}
          px="1px"
        >
          {pricePoints.map((point, i) => (
            <PricePointInput
              key={i}
              point={point.price}
              onValueChange={(value) => {
                const newPricePointsString = pricePoints
                  .map((p, j) => (i === j ? value : p.price.toString()))
                  .join(',')
                updatePricePointsFromString(newPricePointsString)
              }}
            />
          ))}
        </SimpleGrid>
      </Collapse>
      <Button
        onClick={() => setIsExpanded(!isExpanded)}
        variant="link"
        colorScheme="accent"
        size="sm"
        alignSelf="center"
      >
        {isExpanded ? 'Hide points' : 'See points'}
      </Button>
    </VStack>
  )
}

export default PricePointsInput
