import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter
  // Add other Solana wallet adapters as needed
} from '@solana/wallet-adapter-wallets'

// TODO: devnet/mainnet based on env

// Solana network configuration
export const config = {
  endpoint: 'https://api.devnet.solana.com',
  network: WalletAdapterNetwork.Devnet
}

// Wallet adapters
export const wallets = [
  new PhantomWalletAdapter(),
  new SolflareWalletAdapter()
  // Add other wallet adapters here
]
