import { BN, Idl, Program } from '@coral-xyz/anchor'
import { t } from '@lingui/macro'
import * as spl from '@solana/spl-token'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { Keypair, PublicKey, Transaction } from '@solana/web3.js'
import { useMutation } from '@tanstack/react-query'
import { TokenMillChainId } from 'constants/chains'
import { WNATIVE } from 'constants/token'
import { CreateMarketAndTokenArgs } from 'hooks/tokenmill/useCreateMarketAndToken'
import useTransactionToast from 'hooks/useTransactionToast'
import { SCALE, TM_CONFIG } from 'solana/constants'
import TM_IDL from 'solana/idl/token_mill.json'

import { getSwapTransaction } from './useSimulateSwap'

const useCreateTokenMarketSolana = ({
  askPrices,
  bidPrices,
  creatorFeeShareBps,
  name,
  stakingFeeShareBps,
  symbol,
  totalSupply,
  vestingArgs
}: CreateMarketAndTokenArgs) => {
  const { connection } = useConnection()
  const wallet = useWallet()
  const addTransactionToast = useTransactionToast()

  return useMutation({
    mutationFn: async ({ logoURI }: { logoURI: string }) => {
      if (!wallet.publicKey) throw new Error('Wallet not connected')
      if (!totalSupply) throw new Error('Total supply not provided')
      if (!creatorFeeShareBps) throw new Error('Creator fee share not provided')
      if (!stakingFeeShareBps) throw new Error('Staking fee share not provided')

      // Initialize the program
      const program = new Program(TM_IDL as Idl, {
        connection
      })

      // Generate a new keypair for the base token
      const baseTokenKeypair = Keypair.generate()
      const baseToken = baseTokenKeypair.publicKey

      // Derive the mill address
      const [market] = PublicKey.findProgramAddressSync(
        [Buffer.from('market'), baseToken.toBuffer()],
        program.programId
      )

      // Get the market's base token ATA
      const marketBaseTokenATA = spl.getAssociatedTokenAddressSync(
        baseToken,
        market,
        true,
        spl.TOKEN_2022_PROGRAM_ID
      )

      // Get quote token badge
      const quoteToken = new PublicKey(
        WNATIVE[TokenMillChainId.SOLANA_DEVNET].address
      )
      const [quoteTokenBadge] = PublicKey.findProgramAddressSync(
        [
          Buffer.from('quote_token_badge'),
          TM_CONFIG.toBuffer(),
          quoteToken.toBuffer()
        ],
        program.programId
      )

      const transaction = new Transaction()

      // create market
      const createMarketTx = await program.methods
        .createMarket(
          name,
          symbol,
          logoURI,
          new BN(totalSupply * 1e6),
          creatorFeeShareBps,
          stakingFeeShareBps
        )
        .accountsPartial({
          baseTokenMint: baseToken,
          config: TM_CONFIG,
          creator: wallet.publicKey,
          market,
          marketBaseTokenAta: marketBaseTokenATA,
          quoteTokenBadge,
          quoteTokenMint: quoteToken
        })
        .instruction()

      transaction.add(createMarketTx)

      // Set prices
      const setPricesTx = await program.methods
        .setMarketPrices(
          bidPrices.map((price) => new BN(price * SCALE)),
          askPrices.map((price) => new BN(price * SCALE))
        )
        .accountsPartial({
          creator: wallet.publicKey,
          market
        })
        .instruction()
      transaction.add(setPricesTx)

      // Create market quote token ATA
      const marketQuoteTokenATA = spl.getAssociatedTokenAddressSync(
        quoteToken,
        market,
        true,
        spl.TOKEN_PROGRAM_ID
      )
      const createMarketQuoteTokenATAIx =
        spl.createAssociatedTokenAccountInstruction(
          wallet.publicKey,
          marketQuoteTokenATA,
          market,
          quoteToken,
          spl.TOKEN_PROGRAM_ID
        )
      transaction.add(createMarketQuoteTokenATAIx)

      // Buy initial supply if needed
      if (vestingArgs) {
        const swapIx = await getSwapTransaction({
          amountIn: vestingArgs.quoteAmount,
          baseTokenAddress: baseToken.toBase58(),
          connection,
          marketAddress: market.toBase58(),
          minAmountOut: vestingArgs.baseAmount,
          quoteTokenAddress: quoteToken.toBase58(),
          swapType: 'buy',
          walletPublicKey: wallet.publicKey
        })
        transaction.add(swapIx)
      }

      // Get the latest blockhash
      const latestBlockhash = await connection.getLatestBlockhash()

      // Set the transaction's recent blockhash and fee payer
      transaction.recentBlockhash = latestBlockhash.blockhash
      transaction.feePayer = wallet.publicKey

      // Partially sign the transaction with the baseTokenKeypair
      transaction.partialSign(baseTokenKeypair)

      // Send and confirm the transaction
      const signature = await wallet.sendTransaction(transaction, connection)

      addTransactionToast({
        chain: 'solana',
        description: t`Created token`,
        hash: signature,
        walletAddress: wallet.publicKey.toBase58()
      })

      await connection.confirmTransaction(signature, 'confirmed')

      return {
        baseTokenAddress: baseToken.toBase58(),
        hash: signature,
        marketAddress: market.toBase58()
      }
    }
  })
}

export default useCreateTokenMarketSolana
