import {
  Button,
  HStack,
  Input,
  Skeleton,
  Td,
  Text,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import CopyReferredURLButton from 'components/CopyReferredURLButton'
import CurrencyLogo from 'components/CurrencyLogo'
import LoginModal from 'components/LoginModal'
import Web3Button from 'components/Web3Button'
import { WNATIVE } from 'constants/token'
import useClaimReferrerFees from 'hooks/referrals/useClaimReferrerFees'
import useGetPendingReferrerFees from 'hooks/referrals/useGetPendingReferrerFees'
import usePatchReferralCode from 'hooks/referrals/usePatchReferralCode'
import React, { useState } from 'react'
import { useGetAuthTokens, useIsLoggedIn } from 'state/authentication/hooks'
import { AuthTokens, Chain } from 'types/dexbarn'
import { shortenAddress } from 'utils/addresses'
import { getChainId } from 'utils/chains'
import { formattedNum } from 'utils/format'

export interface ReferralTableRowProps {
  chain: Chain
  isLoadingUserReferralCodes: boolean
  nativeCurrencySymbol: string
  referralCode: string | undefined
  walletAddress: string | undefined
}

const ReferralTableRow = ({
  chain,
  isLoadingUserReferralCodes,
  nativeCurrencySymbol,
  referralCode: initialReferralCode,
  walletAddress
}: ReferralTableRowProps) => {
  const chainId = getChainId(chain)
  const quoteTokenAddress = WNATIVE[chainId].address

  const {
    isOpen: isLoginModalOpen,
    onClose: onLoginModalClose,
    onOpen: onLoginModalOpen
  } = useDisclosure()

  const [referralCodeInput, setReferralCodeInput] = useState<string>('')

  const {
    data: patchReferralCodeResponse,
    isPending: isPatchingReferralCode,
    mutateAsync: patchReferralCodeAsync
  } = usePatchReferralCode()
  const isLoggedIn = useIsLoggedIn(chain)
  const { tokens: authTokens } = useGetAuthTokens(chain)

  const handleCreateReferralCode = async (authTokens?: AuthTokens) => {
    if (!isLoggedIn || !authTokens) {
      onLoginModalOpen()
      return
    }

    await patchReferralCodeAsync({
      args: { referral_code: referralCodeInput },
      authTokens
    })
  }

  const referralCode = patchReferralCodeResponse?.code || initialReferralCode

  const {
    isLoadingPendingReferrerFees,
    pendingReferrerFees,
    refetchPendingReferrerFees
  } = useGetPendingReferrerFees({ chain, quoteTokenAddress })

  const { claimAsync, isClaiming } = useClaimReferrerFees({
    chain,
    onClaimSuccess: refetchPendingReferrerFees,
    quoteTokenAddress
  })

  return (
    <Tr>
      <Td pl={0}>
        <HStack>
          <CurrencyLogo boxSize={6} symbol={nativeCurrencySymbol} />
          <Text>{shortenAddress(walletAddress) || '--'}</Text>
        </HStack>
      </Td>
      <Td>
        <Skeleton isLoaded={!isLoadingUserReferralCodes}>
          {referralCode ? (
            <HStack spacing={4}>
              <Text>{referralCode}</Text>
              <CopyReferredURLButton chain={chain} code={referralCode} />
            </HStack>
          ) : (
            '--'
          )}
        </Skeleton>
      </Td>
      <Td isNumeric>
        <Skeleton isLoaded={!isLoadingPendingReferrerFees}>
          <Text>{`${formattedNum(
            pendingReferrerFees?.formatted || '0'
          )} ${nativeCurrencySymbol}`}</Text>
        </Skeleton>
      </Td>
      <Td pr={0} isNumeric>
        <LoginModal
          isOpen={isLoginModalOpen}
          onClose={onLoginModalClose}
          onLoginSuccess={(tokens) => handleCreateReferralCode(tokens)}
          chain={chain}
        />

        {referralCode ? (
          <HStack justify="flex-end">
            <Button
              size="sm"
              variant="boxShadowFlat"
              isLoading={isClaiming}
              loadingText="Claiming"
              isDisabled={
                !claimAsync || pendingReferrerFees?.value === BigInt(0)
              }
              onClick={() => claimAsync?.()}
            >
              Claim Fees
            </Button>
            <Button size="sm" colorScheme="red">
              Delete Code
            </Button>
          </HStack>
        ) : (
          <HStack justify="flex-end">
            <Input
              size="sm"
              placeholder="Enter code"
              maxW="250px"
              value={referralCodeInput}
              onChange={(e) => setReferralCodeInput(e.currentTarget.value)}
            />
            <Web3Button
              chain={chain}
              variant="boxShadowFlat"
              size="sm"
              onClick={() => handleCreateReferralCode(authTokens)}
              isLoading={isPatchingReferralCode}
              loadingText="Creating Code"
            >
              Create Code
            </Web3Button>
          </HStack>
        )}
      </Td>
    </Tr>
  )
}

export default ReferralTableRow
