import { Button } from '@chakra-ui/button'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/modal'
import { Divider, VStack } from '@chakra-ui/react'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import React from 'react'
import { useAreTermsAccepted } from 'state/settings/hooks'

import Disclaimer from './Disclaimer'

interface ConnectWalletModalProps {
  isOpen: boolean
  onClose: () => void
}

const ConnectWalletModal = ({ isOpen, onClose }: ConnectWalletModalProps) => {
  const { areTermsAccepted } = useAreTermsAccepted()

  const { openConnectModal: openConnectModalEVM } = useConnectModal()
  const { setVisible: setSolanaConnectModalVisible } = useWalletModal()

  const handleChainSelection = (chain: 'EVM' | 'Solana') => {
    onClose()
    switch (chain) {
      case 'EVM':
        openConnectModalEVM?.()
        break
      case 'Solana':
        setSolanaConnectModalVisible(true)
        break
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">Select a Wallet</ModalHeader>
        <ModalCloseButton bg="transparent" />

        <ModalBody>
          <Button
            variant="boxShadowFlat"
            bg="bgSecondary"
            onClick={() => handleChainSelection('EVM')}
            mb={4}
            w="full"
            isDisabled={!areTermsAccepted}
          >
            EVM
          </Button>
          <Button
            variant="boxShadowFlat"
            bg="bgSecondary"
            onClick={() => handleChainSelection('Solana')}
            w="full"
            isDisabled={!areTermsAccepted}
          >
            Solana
          </Button>
        </ModalBody>

        <ModalFooter>
          <VStack spacing={4}>
            <Divider />
            <Disclaimer />
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConnectWalletModal
