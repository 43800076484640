import useAddErrorPopup from 'hooks/useAddErrorPopup'
import { useDexbarnClient } from 'hooks/useDexbarn'
import { useCallback } from 'react'
import { AuthTokens } from 'types/dexbarn'

const useRefreshToken = (): ((refreshToken: string) => Promise<AuthTokens>) => {
  const client = useDexbarnClient()
  const addErrorPopup = useAddErrorPopup()

  return useCallback(
    async (refreshToken: string): Promise<AuthTokens> => {
      try {
        const response = await client.get<AuthTokens>(
          `/v1/auth/refresh-token`,
          {
            headers: { Authorization: `Bearer ${refreshToken}` }
          }
        )
        return response.data
      } catch (e) {
        if (e instanceof Error) {
          addErrorPopup({
            subtitle: e.message,
            summary: 'Unable to refresh auth token'
          })
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [client, addErrorPopup]
  )
}

export default useRefreshToken
