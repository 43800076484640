import {
  Box,
  Divider,
  Grid,
  Heading,
  Hide,
  HStack,
  Text,
  VStack
} from '@chakra-ui/react'
import useGetMarket from 'hooks/tokenmill/useGetMarket'
import useGetMarketStakingData from 'hooks/tokenmill/useGetMarketStakingData'
import useGetStakePosition from 'hooks/tokenmill/useGetStakePosition'
import debounce from 'lodash.debounce'
import TokenInfoGridElement from 'pages/TokenMillDetail/TokenInfoGridElement'
import TokenMillDetailHeader from 'pages/TokenMillDetail/TokenMillDetailHeader'
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { Chain } from 'types/dexbarn'
import { isSolanaAddress } from 'utils/addresses'
import { formattedNum } from 'utils/format'
import { isAddress } from 'viem'

import StakeOverview from './StakeOverview'
import StakeUnstakePanel from './StakeUnstakePanel'

interface StakeProps {
  chain: Chain
  marketAddress: string
}

const Stake = ({ chain, marketAddress }: StakeProps) => {
  const { data: market, isLoading: isLoadingMarket } = useGetMarket({
    chain,
    marketAddress
  })

  const {
    data: marketStakingData,
    isLoading: isLoadingMarketStakingData,
    refetch: refetchMarketStakingData
  } = useGetMarketStakingData({
    baseTokenAddress: market?.baseToken.address,
    chain,
    marketAddress
  })
  const debouncedRefetchMarketStakingData = debounce(
    refetchMarketStakingData,
    1000
  )

  const {
    data: userStakePosition,
    isLoading: isLoadingStakePosition,
    refetch: refetchStakePosition
  } = useGetStakePosition({
    baseTokenAddress: market?.baseToken.address,
    chain,
    marketAddress
  })
  const debouncedRefetchStakePosition = debounce(refetchStakePosition, 1000)

  const totalSupplyStakedPercentage =
    market && marketStakingData?.totalStakedAndLockedAmount
      ? (Number(marketStakingData.totalStakedAndLockedAmount.formatted) /
          market.totalSupply) *
        100
      : 0

  return (
    <Box maxW="1600px" margin="0 auto" minH="90svh">
      <Grid
        templateColumns={{ base: '1fr', lg: 'auto 1px 500px' }}
        alignItems="flex-start"
      >
        <VStack spacing={0}>
          <TokenMillDetailHeader
            marketAddress={marketAddress}
            chain={chain}
            token={market?.baseToken}
            tokenLogoUrl={market?.baseTokenLogoUrl}
            twitterUrl={market?.twitterUrl}
            discordUrl={market?.discordUrl}
            telegramUrl={market?.telegramUrl}
            websiteUrl={market?.websiteUrl}
          />

          <Divider />

          <VStack
            align="flex-start"
            w="full"
            p={{ base: 4, md: 6 }}
            spacing={{ base: 4, md: 6 }}
          >
            <Heading size="sm">Staking</Heading>
            <HStack w="full" spacing={{ base: 8, md: 12 }}>
              <TokenInfoGridElement
                title="Total Staked"
                value={
                  marketStakingData?.totalStakedAndLockedAmount
                    ? `${formattedNum(
                        marketStakingData.totalStakedAndLockedAmount.formatted
                      )} ${market?.baseToken.symbol}`
                    : '0'
                }
                isLoading={isLoadingMarketStakingData}
              />
              <TokenInfoGridElement
                title="% Supply Staked"
                value={
                  formattedNum(totalSupplyStakedPercentage, { places: 2 }) + '%'
                }
                isLoading={isLoadingMarket || isLoadingMarketStakingData}
              />
              <TokenInfoGridElement
                title="Staking Fee Share"
                isLoading={isLoadingMarket}
                value={
                  market?.fees
                    ? `${formattedNum(market.fees.stakingFeeShare / 100, {
                        places: 2
                      })}%`
                    : '0%'
                }
              />
              <TokenInfoGridElement title="Fees (24H)" value="TODO" />
            </HStack>
          </VStack>

          <Divider />

          <VStack
            align="flex-start"
            spacing={6}
            p={{ base: 4, md: 6 }}
            w="full"
          >
            <Heading size="sm">STAKING FAQ</Heading>
            <VStack align="flex-start" spacing={1} fontSize="sm">
              <Text>
                • Stake your tokens and claim share of token trading fees
              </Text>
              <Text>
                • Fees are generated when tokens are bought from Token Mill
              </Text>
              <Text>
                • Rewards are distributed in real time and can be claimed at any
                time
              </Text>
              <Text>
                • Fees displayed are only historical guidance and not
                representative of future fees
              </Text>
              <Text>• You may unstake at any time</Text>
            </VStack>
          </VStack>
        </VStack>

        <Hide below="md">
          <Divider orientation="vertical" h="full" />
        </Hide>

        {market && (
          <Box>
            <StakeUnstakePanel
              chain={chain}
              baseToken={market.baseToken}
              baseTokenLogoUrl={market.baseTokenLogoUrl}
              marketAddress={marketAddress}
              userStakePosition={userStakePosition}
              isLoadingUserStakePosition={isLoadingStakePosition}
              onStakeSuccess={() => {
                debouncedRefetchStakePosition()
                debouncedRefetchMarketStakingData()
              }}
              onUnstakeSuccess={() => {
                debouncedRefetchStakePosition()
                debouncedRefetchMarketStakingData()
              }}
            />

            <Divider />

            <StakeOverview
              chain={chain}
              userStakePosition={userStakePosition}
              baseToken={market.baseToken}
              quoteToken={market.quoteCurrency}
              isLoadingUserStakePosition={isLoadingStakePosition}
              marketAddress={marketAddress}
              onClaimPendingRewardsSuccess={() => {
                debouncedRefetchStakePosition()
              }}
            />
          </Box>
        )}
      </Grid>

      <Divider />
    </Box>
  )
}

const StakeWithRedirect = () => {
  const { chain: chainParam, marketAddress } = useParams()

  if (
    !marketAddress ||
    (chainParam !== 'avalanche' && chainParam !== 'solana')
  ) {
    return <Navigate to="/404" />
  }

  const chain = chainParam as Chain

  switch (chain) {
    case 'avalanche':
      if (!isAddress(marketAddress)) {
        return <Navigate to="/404" />
      }
      break
    case 'solana':
      if (!isSolanaAddress(marketAddress)) {
        return <Navigate to="/404" />
      }
      break
  }

  return <Stake marketAddress={marketAddress} chain={chain} />
}

export default StakeWithRedirect
