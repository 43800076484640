import { TokenMillLensAbi } from 'constants/abi/tokenMillLens'
import { TM_LENS } from 'constants/addresses'
import { IS_TESTNET } from 'constants/chains'
import { DEXBARN_TESTNET_WSS_URL, DEXBARN_WSS_URL } from 'constants/dexbarn'
import { CNATIVE } from 'constants/token'
import { useEffect, useRef, useState } from 'react'
import { Chain } from 'types/dexbarn'
import { Market } from 'types/market'
import { getChainId, getChainType } from 'utils/chains'
import { formatUnits, getAddress, zeroAddress } from 'viem'
import { useReadContracts } from 'wagmi'

interface WSMarketData {
  base_token_id: string
  chain: Chain
  creator: string
  icon_url: string
  id: string
  market_created_at: string
  name: string
  quote_token_id: string
  total_supply: string
}

const useLiveMarkets = ({
  chain,
  isPaused
}: {
  chain: Chain
  isPaused: boolean
}) => {
  const chainId = getChainId(chain)
  const chainType = getChainType(chain)

  const nativeCurrency = CNATIVE[chainId]

  const [liveMarkets, setLiveMarkets] = useState<WSMarketData[]>([])
  const [pausedMarkets, setPausedMarkets] = useState<WSMarketData[]>([])
  const wsRef = useRef<WebSocket | null>(null)

  useEffect(() => {
    wsRef.current = new WebSocket(
      `${
        IS_TESTNET ? DEXBARN_TESTNET_WSS_URL : DEXBARN_WSS_URL
      }/v1/markets/ws/${chain}`
    )

    wsRef.current.onmessage = (event) => {
      const newMarket = JSON.parse(event.data)
      if (isPaused) {
        setPausedMarkets((prevMarkets) => [newMarket, ...prevMarkets])
      } else {
        setLiveMarkets((prevMarkets) => [newMarket, ...prevMarkets])
      }
    }

    return () => {
      if (wsRef.current) {
        wsRef.current.close()
      }
    }
  }, [isPaused, chain])

  useEffect(() => {
    if (!isPaused && pausedMarkets.length > 0) {
      setLiveMarkets((prevMarkets) => [...pausedMarkets, ...prevMarkets])
      setPausedMarkets([])
    }
  }, [isPaused, pausedMarkets])

  // TODO: solana
  const { data: markets = [] } = useReadContracts({
    contracts: liveMarkets.map(
      (market) =>
        ({
          abi: TokenMillLensAbi,
          address: getAddress(TM_LENS),
          args: [getAddress(market.id), zeroAddress],
          chainId,
          functionName: 'getSingleDetailedMarketData'
        }) as const
    ),
    query: {
      enabled: chainType === 'evm',
      select: (data) => {
        return data
          .map(({ result: marketData }, i) => {
            const wsMarketData = liveMarkets[i]
            if (!marketData) {
              return undefined
            }

            return {
              baseToken: {
                address: marketData.baseToken,
                decimals: Number(marketData.baseTokenDecimals),
                name: marketData.baseTokenName,
                priceNative: formatUnits(marketData.spotPriceFillBid, 18),
                priceUsd: 0,
                symbol: marketData.baseTokenSymbol
              },
              baseTokenPctChange: 0,
              chain: wsMarketData.chain,
              circulatingSupply: Number(
                formatUnits(
                  marketData.circulatingSupply,
                  Number(marketData.baseTokenDecimals)
                )
              ),
              circulatingSupplyUsd: 0,
              createdAt: new Date(wsMarketData.market_created_at),
              description: '',
              liquidityUsd: 0,
              logoUrl: wsMarketData.icon_url,
              marketAddress: wsMarketData.id,
              quoteToken: {
                address: marketData.quoteToken,
                decimals: Number(marketData.quoteTokenDecimals),
                name: marketData.quoteTokenName,
                priceNative: '1',
                priceUsd: 0,
                symbol: nativeCurrency.symbol
              },
              volumeUsd: 0
            } satisfies Market
          })
          .filter(Boolean) as Market[]
      }
    }
  })

  return {
    data: markets,
    pausedMarketsCount: pausedMarkets.length
  }
}

export default useLiveMarkets
