import { createReducer } from '@reduxjs/toolkit'

import {
  setAreTermsAccepted,
  setIsUKDisclaimerHidden,
  setOriginCountryCode
} from './actions'

interface SettingsState {
  readonly areTermsAccepted?: boolean
  readonly isUKDisclaimerHidden?: boolean
  readonly originCountryCode?: string
}

const initialState: SettingsState = {
  areTermsAccepted: false
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setAreTermsAccepted, (state, action) => {
      state.areTermsAccepted = action.payload
    })

    .addCase(setOriginCountryCode, (state, action) => {
      state.originCountryCode = action.payload
    })
    .addCase(setIsUKDisclaimerHidden, (state, action) => {
      state.isUKDisclaimerHidden = action.payload
    })
)
