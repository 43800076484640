import { IS_TESTNET } from 'constants/chains'
import { DEXBARN_TESTNET_WSS_URL, DEXBARN_WSS_URL } from 'constants/dexbarn'
import { CNATIVE } from 'constants/token'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Chain } from 'types/dexbarn'
import { RecentTrade } from 'types/market'
import { getChainId, getChainType } from 'utils/chains'
import { formatUnits } from 'viem'

interface WSTradeData {
  base_token_id: string
  base_token_price_native: string
  base_token_price_usd: string
  block_number: number
  chain: Chain
  delta_base_amount: string
  delta_base_amount_native: string
  delta_base_amount_usd: string
  delta_quote_amount: string
  delta_quote_amount_native: string
  delta_quote_amount_usd: string
  id: string
  log_index: number
  market_id: string
  quote_token_id: string
  quote_token_price_native: string
  quote_token_price_usd: string
  recipient: string
  sender: string
  swap_type: 'ask' | 'bid'
  timestamp: string
  transaction_hash: string
}

interface UseLiveTradesProps {
  baseTokenSymbol: string
  chain: Chain
  isPaused: boolean
  marketAddress: string
}

const useLiveTrades = ({
  baseTokenSymbol,
  chain,
  isPaused,
  marketAddress
}: UseLiveTradesProps) => {
  const chainId = getChainId(chain)
  const chainType = getChainType(chain)

  const nativeCurrency = CNATIVE[chainId]

  const [liveTrades, setLiveTrades] = useState<WSTradeData[]>([])
  const [pausedTrades, setPausedTrades] = useState<WSTradeData[]>([])
  const wsRef = useRef<WebSocket | null>(null)

  useEffect(() => {
    wsRef.current = new WebSocket(
      `${
        IS_TESTNET ? DEXBARN_TESTNET_WSS_URL : DEXBARN_WSS_URL
      }/v1/markets/ws/${chain}/${marketAddress}`
    )

    wsRef.current.onmessage = (event) => {
      const newTrade = JSON.parse(event.data)
      if (isPaused) {
        setPausedTrades((prevTrades) => [newTrade, ...prevTrades])
      } else {
        setLiveTrades((prevTrades) => [newTrade, ...prevTrades])
      }
    }

    return () => {
      if (wsRef.current) {
        wsRef.current.close()
      }
    }
  }, [isPaused, marketAddress, chain])

  useEffect(() => {
    if (!isPaused && pausedTrades.length > 0) {
      setLiveTrades((prevTrades) => [...pausedTrades, ...prevTrades])
      setPausedTrades([])
    }
  }, [isPaused, pausedTrades])

  const trades: RecentTrade[] = useMemo(() => {
    return liveTrades.map((trade) => {
      const baseToken = {
        address: trade.base_token_id,
        decimals: 18,
        priceUsd: parseFloat(trade.base_token_price_usd),
        symbol: baseTokenSymbol
      }

      const quoteToken = {
        address: trade.quote_token_id,
        decimals: chainType === 'solana' ? 9 : 18,
        priceUsd: parseFloat(trade.quote_token_price_usd),
        symbol: nativeCurrency.symbol
      }

      return {
        amountIn: formatUnits(
          BigInt(
            trade.swap_type === 'ask'
              ? trade.delta_base_amount
              : trade.delta_quote_amount
          ),
          trade.swap_type === 'ask' ? baseToken.decimals : quoteToken.decimals
        ),
        amountOut: formatUnits(
          BigInt(-1) *
            BigInt(
              trade.swap_type === 'ask'
                ? trade.delta_quote_amount
                : trade.delta_base_amount
            ),
          trade.swap_type === 'ask' ? quoteToken.decimals : baseToken.decimals
        ),
        baseTokenPriceUsd: Number(trade.base_token_price_usd),
        orderType: trade.swap_type,
        timestamp: trade.timestamp,
        tokenIn: trade.swap_type === 'ask' ? baseToken : quoteToken,
        tokenOut: trade.swap_type === 'ask' ? quoteToken : baseToken,
        txnHash: trade.transaction_hash,
        userAddress: trade.sender
      }
    })
  }, [liveTrades, baseTokenSymbol, chainType, nativeCurrency.symbol])

  return {
    data: trades,
    pausedDataCount: pausedTrades.length
  }
}

export default useLiveTrades
