import {
  extendTheme,
  SystemStyleObject,
  ThemeComponents,
  ThemeConfig
} from '@chakra-ui/react'

import { buttonTheme } from './button'
import { fontStyle } from './fonts'
import { inputTheme } from './input'
import { linkTheme } from './link'
import { menuTheme } from './menu'
import { modalTheme } from './modal'
import { popoverTheme } from './popover'
import { slidersTheme } from './slider'
import { switchTheme } from './switch'
import { tableTheme } from './table'
import { tabsTheme } from './tabs'

const styles: { global: { [key: string]: SystemStyleObject } } = {
  global: {
    '#chakra-toast-manager-top-right': {
      top: 'calc(env(safe-area-inset-top, 0px) + var(--toast-top-offset)) !important'
    },
    ':root': {
      '--toast-top-offset': '76px'
    },
    body: {
      bg: 'bgPrimary',
      transitionDuration: 'fast !important', // 150ms
      transitionProperty: 'background !important',
      transitionTimingFunction: 'ease-in-out !important'
    }
  }
}

const colors = {
  accent: {
    100: '#FFF0EB',
    200: '#FFD6C7',
    300: '#FFBBA3',
    400: '#FFA17F',
    500: '#fd6c3c',
    600: '#E85A2B',
    700: '#D3491C',
    800: '#BE390F',
    900: '#A92C05'
  },
  blueGray: '#e7e5d9',
  candleGreen: {
    dark: '#6efd3c',
    light: '#4adc00'
  },
  candleRed: '#fd493c',
  charcoal: '#111117',
  green: {
    100: '#d5f5c9',
    200: '#93ee73',
    300: '#6fd24c',
    400: '#5eb53f',
    500: '#54903e'
  },
  joeDark: {
    300: '#9295bf',
    400: '#474a66',
    500: '#2f3146',
    600: '#1f202e',
    700: '#181823'
  },
  joeLight: {
    300: '#fbfbff',
    400: '#f0f0ff',
    500: '#ececfe',
    600: '#d5d5fb',
    700: '#8c8cb1'
  },
  lightShade: '#f7f5ee',
  lightWhite: '#fdfbf7',
  slate: '#2b2b3b',
  textSecondaryDark: '#9295bf',
  textSecondaryLight: '#8c8cb1',
  yellowBar: '#fad65e'
}

const shadows = {
  joeDark: '4px 4px 0px 0px rgba(0, 0, 0, 0.25)',
  joeLight: '4px 4px 0px 0px rgba(0, 0, 0, 0.1)'
}

const semanticTokens = {
  colors: {
    bgPrimary: {
      _dark: 'charcoal',
      _light: 'lightWhite'
    },
    bgSecondary: {
      _dark: 'slate',
      _light: '#f7f5ee'
    },
    border: {
      _dark: 'slate',
      _light: '#e0e0e0'
    },
    candleGreen: {
      _light: 'candleGreen.light',
      default: 'candleGreen.dark'
    },
    hover: {
      _dark: '#1e2030',
      _light: '#eeece5'
    },
    textPrimary: {
      _dark: 'white',
      _light: '#190037'
    },
    textSecondary: {
      _dark: 'textSecondaryDark',
      _light: 'textSecondaryLight'
    }
  }
}

const components: ThemeComponents = {
  Button: buttonTheme,
  Divider: {
    baseStyle: {
      borderColor: 'border'
    }
  },
  Heading: {
    baseStyle: {
      fontWeight: 'semibold',
      textTransform: 'uppercase'
    }
  },
  Input: inputTheme,
  Link: linkTheme,
  Menu: menuTheme,
  Modal: modalTheme,
  Popover: popoverTheme,
  Slider: slidersTheme,
  Switch: switchTheme,
  Table: tableTheme,
  Tabs: tabsTheme,
  Text: {
    baseStyle: {
      color: 'textPrimary'
    }
  }
}

const fonts = {
  body: fontStyle,
  heading: fontStyle,
  html: fontStyle
}

const config: ThemeConfig = {
  initialColorMode: 'dark'
}

export const chakraTheme = extendTheme({
  colors,
  components,
  config,
  fonts,
  semanticTokens,
  shadows,
  styles
})
