import { tableAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys)

const simple = definePartsStyle(() => ({
  td: {
    borderBottom: '0',
    fontSize: 'sm',
    px: 4,
    py: 2
  },
  th: {
    borderBottom: '0',
    px: 4,
    py: { base: 4, md: 6 }
  }
}))

export const tableTheme = defineMultiStyleConfig({
  variants: { simple }
})
