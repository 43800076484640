import { IS_TESTNET } from 'constants/chains'
import { apiGatewayClient, apiGatewayTestnetClient } from 'constants/gateway'
import { useEffect } from 'react'
import { useOriginCountry } from 'state/settings/hooks'

const updateToastManagerTopMargin = (countryCode: string) => {
  const top = countryCode === 'GB' ? '152px' : '76px'
  document.documentElement.style.setProperty('--toast-top-offset', top)
}

const useLoadOriginCountry = () => {
  const client = IS_TESTNET ? apiGatewayTestnetClient : apiGatewayClient

  const { updateOriginCountryCode } = useOriginCountry()

  useEffect(() => {
    const fetch = async () => {
      const { headers } = await client.get('')
      const countryCode = headers['x-tj-country'] as string
      return countryCode
    }

    fetch().then((countryCode) => {
      updateOriginCountryCode(countryCode)
      updateToastManagerTopMargin(countryCode)
    })
  }, [client, updateOriginCountryCode])
}

export default useLoadOriginCountry
