import {
  Box,
  Center,
  HStack,
  SimpleGrid,
  SkeletonCircle,
  SkeletonText,
  Text,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { MarketData } from 'types/dexbarn'

import MarketSearchResultRow from './MarketSearchResultRow'

interface SearchResultsProps {
  isLoadingMarkets: boolean
  markets: MarketData[]
  onRowClick: () => void
  activeResultIndex?: number
  containerRef?: React.RefObject<HTMLDivElement>
  onMouseEnter?: (index: number) => void
}

const SearchResults = ({
  activeResultIndex,
  containerRef,
  isLoadingMarkets,
  markets,
  onMouseEnter,
  onRowClick
}: SearchResultsProps) => {
  return markets.length > 0 || isLoadingMarkets ? (
    <Box ref={containerRef}>
      <VStack align="flex-start" spacing={0} p={2}>
        <Text size="sm" pl={4} textColor="textSecondary">
          <Trans>Tokens</Trans>
        </Text>
        {isLoadingMarkets ? (
          <SearchResultRowSkeleton />
        ) : (
          <SimpleGrid columns={1} gap={1} w="full">
            {markets.map((market, i) => (
              <MarketSearchResultRow
                key={market.marketAddress}
                id={`search-result-${i}`}
                isHighlighted={activeResultIndex === i}
                market={market}
                onClick={onRowClick}
                onMouseEnter={() => onMouseEnter?.(i)}
              />
            ))}
          </SimpleGrid>
        )}
      </VStack>
    </Box>
  ) : (
    <Center p={8}>
      <Text color="textSecondary">
        <Trans>No results found</Trans>
      </Text>
    </Center>
  )
}

export default SearchResults

const SearchResultRowSkeleton = () => {
  return (
    <SimpleGrid columns={{ '2xl': 2, base: 1 }} gap={4} w="full" p={4}>
      <HStack spacing={4}>
        <SkeletonCircle boxSize={8} />
        <SkeletonText w="300px" noOfLines={2} />
      </HStack>
      <HStack spacing={4}>
        <SkeletonCircle boxSize={8} />
        <SkeletonText w="300px" noOfLines={2} />
      </HStack>
    </SimpleGrid>
  )
}
