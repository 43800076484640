import { useQuery } from '@tanstack/react-query'
import useAccountOnChain from 'hooks/tokenmill/useAccountOnChain'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { Chain } from 'types/dexbarn'

// TODO: get referrer code for solana
const useGetUserReferralCodes = () => {
  const { address: addressEVM } = useAccountOnChain('avalanche')

  const fetchReferrerCode = useDexbarnGet<string>(
    `/v1/users/referrer-code/${addressEVM}`
  )

  return useQuery({
    enabled: !!addressEVM,
    queryFn: () => fetchReferrerCode().catch(() => ''),
    queryKey: ['GetReferrerCode', addressEVM],
    select: (data) => {
      return {
        avalanche: data,
        solana: ''
      } as { [chain in Chain]: string }
    }
  })
}

export default useGetUserReferralCodes
