import { useDexbarnClient } from 'hooks/useDexbarn'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useReferralStorage } from 'state/referral'

const ReferralHandler: React.FC = () => {
  const dexbarnClient = useDexbarnClient()
  const location = useLocation()
  const { referralState, updateReferralInfo } = useReferralStorage()

  useEffect(() => {
    const handleReferralCode = async (refcode: string) => {
      // TODO: fetch solana address too
      const referrerAddress = await dexbarnClient
        .get<string>(`/v1/users/referrer-address/${refcode}`)
        .then((r) => r.data)
        .catch(() => null)

      if (referrerAddress) {
        updateReferralInfo('evm', refcode, referrerAddress)
      }
    }

    const searchParams = new URLSearchParams(location.search)
    const urlReferralCode = searchParams.get('r')

    const isAlreadyReferredOnEVM = !!referralState.evm.code

    // Only update if there's a code in the URL and no existing code stored
    if (urlReferralCode && !isAlreadyReferredOnEVM) {
      handleReferralCode(urlReferralCode)
    }
  }, [location, updateReferralInfo, dexbarnClient, referralState])

  return null
}

export default ReferralHandler
