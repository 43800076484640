import {
  Box,
  Center,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import React from 'react'
import { Chain } from 'types/dexbarn'
import { VestingData } from 'types/market'

import VestedTokenTableRow from './VestedTokenTableRow'

interface Props {
  baseTokenAddress: string | undefined
  chain: Chain
  data: VestingData | undefined
  isLoading: boolean
  onReleaseSuccess: () => void
}
const VestedTokensTable: React.FC<Props> = ({
  baseTokenAddress,
  chain,
  data,
  isLoading,
  onReleaseSuccess
}) => {
  return (
    <Box w="full">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Beneficiary</Th>
            <Th>Vesting Schedule</Th>
            <Th>Cliff Date</Th>
            <Th>Unlock Rate</Th>
            <Th>Releasable</Th>
            <Th>Progress</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <Tr>
              <Td colSpan={6}>
                <Center minH="200px">
                  <Spinner />
                </Center>
              </Td>
            </Tr>
          ) : data && data.vestingSchedules.length > 0 && baseTokenAddress ? (
            data.vestingSchedules.map((schedule, index) => (
              <VestedTokenTableRow
                key={index}
                schedule={schedule}
                chain={chain}
                baseTokenAddress={baseTokenAddress}
                onReleaseSuccess={onReleaseSuccess}
              />
            ))
          ) : (
            <Tr>
              <Td colSpan={6}>
                <Center h="200px">
                  <Text fontSize="sm">No vesting schedules found</Text>
                </Center>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  )
}

export default VestedTokensTable
