import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys
)

const bracketed = {
  tab: {
    _before: {
      content: "'[ ]'",
      marginRight: '8px'
    },
    _selected: {
      _before: {
        content: "'[X]'",
        marginRight: '8px'
      },
      bg: '#3cb7fd',
      color: '#181823'
    },
    color: 'textPrimary',
    fontSize: 'sm',
    fontWeight: 'medium',
    mr: 2,
    position: 'relative',
    px: 1,
    py: 2
  },
  tablist: {
    border: 'none'
  }
}

export const tabsTheme = defineMultiStyleConfig({
  variants: {
    bracketed
  }
})
