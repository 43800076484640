import { TokenMillLensAbi } from 'constants/abi/tokenMillLens'
import { TM_LENS } from 'constants/addresses'
import { CNATIVE, WNATIVE } from 'constants/token'
import useGetMarketFromBarn from 'hooks/barn/evm/useGetMarketFromBarn'
import { UseGetMarketProps } from 'hooks/tokenmill/useGetMarket'
import { useMemo } from 'react'
import { PricePoint } from 'utils/bondingCurves'
import { getChainId, getChainType } from 'utils/chains'
import { formatUnits, getAddress, isAddress, zeroAddress } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

const useGetMarketEVM = ({ chain, marketAddress }: UseGetMarketProps) => {
  const chainId = getChainId(chain)
  const chainType = getChainType(chain)
  const { address: userAddress } = useAccount()

  const { data: dexbarnMarket } = useGetMarketFromBarn({
    chain,
    enabled: chainType === 'evm',
    marketAddress
  })

  const {
    data: marketMetadata,
    isLoading,
    refetch: refetchMarketMetadata
  } = useReadContract({
    abi: TokenMillLensAbi,
    address: TM_LENS,
    args: isAddress(marketAddress)
      ? [getAddress(marketAddress), userAddress || zeroAddress]
      : undefined,
    chainId,
    functionName: 'getSingleDetailedMarketData',
    query: { enabled: chainType === 'evm' }
  })

  const market = useMemo(() => {
    if (!marketMetadata || !marketMetadata.marketExists) return undefined

    const circulatingSupply = Number(
      formatUnits(
        marketMetadata.circulatingSupply,
        Number(marketMetadata.baseTokenDecimals)
      )
    )
    const circulatingSupplyUsd = dexbarnMarket?.baseMarketCapUsd

    const totalSupply = Number(
      formatUnits(
        marketMetadata.totalSupply,
        Number(marketMetadata.baseTokenDecimals)
      )
    )

    const askPrice = Number(
      formatUnits(
        marketMetadata.spotPriceFillAsk,
        Number(marketMetadata.quoteTokenDecimals)
      )
    )
    const bidPrice = Number(
      formatUnits(
        marketMetadata.spotPriceFillBid,
        Number(marketMetadata.quoteTokenDecimals)
      )
    )

    const numPoints = marketMetadata.askPrices.length
    const askPricesPoints: PricePoint[] = marketMetadata.askPrices.map(
      (price, i) => ({
        price: Number(
          formatUnits(price, Number(marketMetadata.quoteTokenDecimals))
        ),
        supply: (totalSupply / (numPoints - 1)) * i
      })
    )
    const bidPricesPoints: PricePoint[] = marketMetadata.bidPrices.map(
      (price, i) => ({
        price: Number(
          formatUnits(price, Number(marketMetadata.quoteTokenDecimals))
        ),
        supply: (totalSupply / (numPoints - 1)) * i
      })
    )

    return {
      askPrice,
      askPricesPoints,
      baseToken: {
        address: marketMetadata.baseToken,
        decimals: Number(marketMetadata.baseTokenDecimals),
        name: marketMetadata.baseTokenName,
        symbol: marketMetadata.baseTokenSymbol,
        type: Number(marketMetadata.baseTokenType)
      },
      baseTokenLogoUrl: dexbarnMarket?.iconUrl,
      baseTokenPriceNative: dexbarnMarket?.baseToken.priceNative,
      baseTokenPriceUsd: dexbarnMarket?.baseToken.priceUsd,
      bidPrice,
      bidPricesPoints,
      chain,
      circulatingSupply,
      createdAt: dexbarnMarket?.marketCreatedAt,
      creator: marketMetadata.marketCreator,
      description: dexbarnMarket?.description,
      discordUrl: dexbarnMarket?.discordUrl,
      fees: {
        creatorShare: Number(marketMetadata.creatorShare),
        pendingCreatorFees: marketMetadata.creatorPendingFees,
        protocolPendingFees: Number(
          formatUnits(
            marketMetadata.protocolPendingFees,
            Number(marketMetadata.quoteTokenDecimals)
          )
        ),
        protocolShare: Number(marketMetadata.protocolShare),
        referrerPendingFees: Number(
          formatUnits(
            marketMetadata.referrerPendingFees,
            Number(marketMetadata.quoteTokenDecimals)
          )
        ),
        referrerShare: Number(marketMetadata.referrerShare),
        stakingFeeShare: Number(marketMetadata.stakingShare),
        stakingPendingFees: Number(
          formatUnits(
            marketMetadata.stakingPendingFees,
            Number(marketMetadata.quoteTokenDecimals)
          )
        )
      },
      liquidityUsd: dexbarnMarket?.quoteLiquidityUsd,
      marketAddress,
      marketCapUsd: circulatingSupplyUsd,
      quoteCurrency:
        marketMetadata.quoteToken === WNATIVE[chainId].address
          ? CNATIVE[chainId]
          : {
              address: marketMetadata.quoteToken,
              decimals: Number(marketMetadata.quoteTokenDecimals),
              name: marketMetadata.quoteTokenName,
              symbol: marketMetadata.quoteTokenSymbol
            },
      staking: {
        totalLocked: Number(
          formatUnits(
            marketMetadata.totalLocked,
            Number(marketMetadata.baseTokenDecimals)
          )
        ),
        totalStaked: Number(
          formatUnits(
            marketMetadata.totalStaked,
            Number(marketMetadata.baseTokenDecimals)
          )
        )
      },
      telegramUrl: dexbarnMarket?.telegramUrl,
      totalSupply,
      twitterUrl: dexbarnMarket?.twitterUrl,
      websiteUrl: dexbarnMarket?.websiteUrl
    }
  }, [marketMetadata, chainId, chain, marketAddress, dexbarnMarket])

  return {
    data: market,
    isLoading,
    refetch: () => {
      refetchMarketMetadata()
    }
  }
}

export default useGetMarketEVM
