import {
  Center,
  Image,
  InputGroup,
  InputRightElement,
  Skeleton,
  TabPanel,
  Text,
  VStack
} from '@chakra-ui/react'
import InputBalanceButton from 'components/InputBalanceButton'
import NumericalInput from 'components/NumericalInput'
import Web3Button from 'components/Web3Button'
import useUnstakeTokens from 'hooks/tokenmill/useUnstakeTokens'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import React, { useMemo } from 'react'
import { Chain } from 'types/dexbarn'
import { UserStakePosition } from 'types/market'
import { Token } from 'types/token'
import { formattedNum } from 'utils/format'

interface UnstakePanelProps {
  baseToken: Token
  chain: Chain
  isLoadingUserStakePosition: boolean
  marketAddress: string
  onUnstakeSuccess: () => void
  baseTokenLogoUrl?: string
  userStakePosition?: UserStakePosition
}

const UnstakePanel = ({
  baseToken,
  baseTokenLogoUrl,
  chain,
  isLoadingUserStakePosition,
  marketAddress,
  onUnstakeSuccess,
  userStakePosition
}: UnstakePanelProps) => {
  const { amount, amountBN, setAmount } = useCurrencyInputAmount({
    currency: baseToken
  })

  const { isUnstaking, resetUnstake, unstakeAsync } = useUnstakeTokens({
    amount: amountBN,
    baseTokenAddress: baseToken.address,
    baseTokenSymbol: baseToken.symbol,
    chain,
    marketAddress,
    onUnstakeSuccess
  })

  const amountErrorMsg = useMemo(() => {
    if (!amountBN) return ''

    if (!userStakePosition?.amountStaked) return ''
    if (amountBN > userStakePosition.amountStaked.value) {
      return `You have ${formattedNum(
        userStakePosition.amountStaked.formatted
      )} ${baseToken.symbol} staked`
    }
    return ''
  }, [amountBN, userStakePosition, baseToken.symbol])

  const handleUnstake = async () => {
    await unstakeAsync?.()

    setAmount('')
    resetUnstake()
  }

  const handleSetMax = () => {
    if (userStakePosition?.amountStaked) {
      setAmount(userStakePosition.amountStaked.formatted)
    }
  }

  return (
    <TabPanel px={0} pb={0}>
      <VStack spacing={2}>
        <Skeleton isLoaded={!isLoadingUserStakePosition} alignSelf="flex-end">
          <InputBalanceButton
            balance={userStakePosition?.amountStaked?.formatted ?? '0'}
            onClick={handleSetMax}
            title="Staked:"
          />
        </Skeleton>
        <InputGroup>
          <NumericalInput
            placeholder="0"
            w="full"
            size="lg"
            value={amount}
            onValueChange={setAmount}
          />
          <InputRightElement w="fit-content" h="full" pr={4}>
            <Image src={baseTokenLogoUrl} boxSize={6} objectFit="contain" />
          </InputRightElement>
        </InputGroup>
      </VStack>

      <Web3Button
        chain={chain}
        size="lg"
        variant="boxShadowFlat"
        bg="accent.500"
        mt={8}
        w="full"
        isDisabled={
          !amount || !!amountErrorMsg || amountBN === BigInt(0) || !unstakeAsync
        }
        onClick={handleUnstake}
        isLoading={isUnstaking}
        loadingText="Unstaking"
      >
        {'[UNSTAKE]'}
      </Web3Button>

      {amountErrorMsg ? (
        <Center mt={4}>
          <Text textColor="red.500" fontSize="sm">
            {amountErrorMsg}
          </Text>
        </Center>
      ) : null}
    </TabPanel>
  )
}

export default UnstakePanel
