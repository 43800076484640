import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const MobileNavigationMenu = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const navigate = useNavigate()

  const items = [
    {
      title: '[Home]',
      url: '/'
    },
    { title: '[Create Token]', url: '/new' }
  ]

  return (
    <>
      <Button
        aria-label="Click for links to Bridge, Farm, Lend and Stake on Trader Joe"
        variant="ghost"
        color="textPrimary"
        _hover={{ bg: 'bgTertiary' }}
        _active={{ bg: 'bgTertiary', color: 'textPrimary' }}
        onClick={onOpen}
      >
        [Menu]
      </Button>
      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent bg="bgPrimary">
          <DrawerCloseButton />
          <DrawerBody>
            <Flex
              gap={4}
              w="full"
              align="center"
              flexDir="column"
              justifyContent="center"
              p={4}
            >
              {items.map((item, i) => {
                return (
                  <Flex
                    key={i}
                    w="full"
                    h={10}
                    justifyContent="center"
                    align="center"
                    _hover={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(item.url)
                      onClose()
                    }}
                  >
                    <Text textAlign="center" w="full" fontWeight="semibold">
                      {item.title}
                    </Text>
                  </Flex>
                )
              })}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MobileNavigationMenu
