import { CloseIcon } from '@chakra-ui/icons'
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  IconButton,
  Input,
  InputGroup
} from '@chakra-ui/react'
import SearchResults from 'components/HeaderSearchPanel/SearchResults'
import useSearchMarkets from 'hooks/tokenmill/useSearchMarkets'
import React, { useState } from 'react'

interface MobileSearchPanelProps {
  isOpen: boolean
  onClose: () => void
}

const MobileSearchPanel = ({
  isOpen,
  onClose
}: MobileSearchPanelProps): JSX.Element => {
  const [query, setQuery] = useState('')
  const { data: markets = [], isLoading: isLoadingMarkets } = useSearchMarkets({
    query
  })

  return (
    <Drawer placement="right" isOpen={isOpen} onClose={onClose} size="full">
      <DrawerContent p={0} bg="bgPrimary">
        <DrawerHeader py={6} px={4}>
          <InputGroup>
            <Input
              h="full"
              minH="40px"
              w="full"
              background="transparent"
              border={0}
              borderRadius={0}
              _focus={{ outline: 'none' }}
              placeholder="Search by symbol, name or address"
              onChange={(e) => setQuery(e.target.value)}
            />
            <IconButton
              aria-label="close search"
              bg="bgSecondary"
              icon={<CloseIcon boxSize="12px" />}
              onClick={onClose}
            />
          </InputGroup>
        </DrawerHeader>
        <DrawerBody p={0}>
          <SearchResults
            markets={markets}
            isLoadingMarkets={isLoadingMarkets}
            onRowClick={onClose}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default MobileSearchPanel
