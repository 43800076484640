import { PlusSquareIcon } from '@chakra-ui/icons'
import { Box, Image, Input, Text, VStack } from '@chakra-ui/react'
import useErrorToast from 'hooks/useErrorToast'
import React, { useEffect, useRef, useState } from 'react'

interface ImagePickerProps {
  selectedImage: File | null
  setSelectedImage: (file: File | null) => void
}

const MAX_FILE_SIZE = 1048576 // 1MB in bytes

const ImagePicker = ({ selectedImage, setSelectedImage }: ImagePickerProps) => {
  const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const toast = useErrorToast()

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        toast(
          'File too large',
          'Please select an image smaller than 1MB',
          'file_too_large'
        )
        setSelectedImage(null)
        setImageUrl(null)
      } else {
        setSelectedImage(file)
      }
    }
  }

  useEffect(() => {
    if (!selectedImage) return

    const reader = new FileReader()
    reader.onloadend = () => {
      setImageUrl(reader.result)
    }
    reader.readAsDataURL(selectedImage)
  }, [selectedImage])

  const handleBoxClick = () => {
    inputRef.current?.click()
  }

  return (
    <Box textAlign="center">
      <Input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        ref={inputRef}
        display="none"
      />
      <Box
        width="full"
        height="full"
        maxH="160px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        onClick={handleBoxClick}
        borderWidth="1px"
        borderColor="border"
        _hover={{
          opacity: 0.5
        }}
        transition="border-color 0.1s"
      >
        {imageUrl ? (
          <Image
            src={imageUrl as string}
            alt="Selected"
            maxW="100%"
            maxH="100%"
          />
        ) : (
          <VStack p={2}>
            <PlusSquareIcon boxSize={5} color="textSecondary" />
            <Text textColor="textSecondary" fontSize="sm">
              Click to select an image
            </Text>
          </VStack>
        )}
      </Box>
    </Box>
  )
}

export default ImagePicker
