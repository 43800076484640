import { BN, Idl, Program } from '@coral-xyz/anchor'
import * as spl from '@solana/spl-token'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { Keypair, PublicKey, Transaction } from '@solana/web3.js'
import { useMutation } from '@tanstack/react-query'
import { VestingArgs } from 'hooks/tokenmill/useCreateMarketAndToken'
import useTransactionToast from 'hooks/useTransactionToast'
import { useCallback } from 'react'
import TokenMillIdl from 'solana/idl/token_mill.json'
import { Chain } from 'types/dexbarn'

interface UseCreateVestingPlanProps {
  chain: Chain
  vestingArgs?: VestingArgs
}

const useCreateVestingPlan = ({
  chain,
  vestingArgs
}: UseCreateVestingPlanProps) => {
  const { connection } = useConnection()
  const wallet = useWallet()
  const addTransactionToast = useTransactionToast()

  const getCreateStakingInstruction = useCallback(
    async (program: Program, market: PublicKey, staking: PublicKey) => {
      if (!wallet.publicKey) throw new Error('Wallet not connected')

      const stakingAccountInfo = await connection.getAccountInfo(staking)

      if (!stakingAccountInfo) {
        return program.methods
          .createStaking()
          .accounts({
            market,
            payer: wallet.publicKey,
            staking
          })
          .instruction()
      }

      return null
    },
    [connection, wallet.publicKey]
  )

  const getCreateStakePositionInstruction = useCallback(
    async (program: Program, market: PublicKey, stakePosition: PublicKey) => {
      if (!wallet.publicKey) throw new Error('Wallet not connected')

      const stakePositionAccountInfo =
        await connection.getAccountInfo(stakePosition)

      if (!stakePositionAccountInfo) {
        return program.methods
          .createStakePosition()
          .accounts({
            market,
            stakePosition,
            user: wallet.publicKey
          })
          .instruction()
      }

      return null
    },
    [connection, wallet.publicKey]
  )

  const getCreateVestingPlanTransaction = useCallback(
    async (marketAddress: string, baseTokenAddress: string) => {
      if (!wallet.publicKey) throw new Error('Wallet not connected')
      if (!vestingArgs) throw new Error('vestingArgs not provided')

      const program = new Program(TokenMillIdl as Idl, {
        connection
      })
      const market = new PublicKey(marketAddress)
      const baseTokenMint = new PublicKey(baseTokenAddress)

      const staking = PublicKey.findProgramAddressSync(
        [Buffer.from('market_staking'), market.toBuffer()],
        program.programId
      )[0]

      const stakePosition = PublicKey.findProgramAddressSync(
        [
          Buffer.from('stake_position'),
          market.toBuffer(),
          wallet.publicKey.toBuffer()
        ],
        program.programId
      )[0]

      const marketBaseTokenAta = spl.getAssociatedTokenAddressSync(
        baseTokenMint,
        market,
        true,
        spl.TOKEN_2022_PROGRAM_ID
      )

      const userBaseTokenAta = spl.getAssociatedTokenAddressSync(
        baseTokenMint,
        wallet.publicKey,
        true,
        spl.TOKEN_2022_PROGRAM_ID
      )

      const vestingPlanKeypair = Keypair.generate()

      const transaction = new Transaction()

      const createStakingIx = await getCreateStakingInstruction(
        program,
        market,
        staking
      )
      if (createStakingIx) transaction.add(createStakingIx)

      const createStakePositionIx = await getCreateStakePositionInstruction(
        program,
        market,
        stakePosition
      )
      if (createStakePositionIx) transaction.add(createStakePositionIx)

      const userBaseTokenAccountBalance =
        await connection.getTokenAccountBalance(userBaseTokenAta)
      const createVestingPlanIx = await program.methods
        .createVestingPlan(
          new BN(vestingArgs.start),
          new BN(userBaseTokenAccountBalance.value.amount),
          new BN(vestingArgs.vestingDuration),
          new BN(vestingArgs.cliffDuration)
        )
        .accounts({
          baseTokenMint,
          baseTokenProgram: spl.TOKEN_2022_PROGRAM_ID,
          market,
          marketBaseTokenAta,
          stakePosition,
          staking,
          user: wallet.publicKey,
          userBaseTokenAta,
          vestingPlan: vestingPlanKeypair.publicKey
        })
        .instruction()

      transaction.add(createVestingPlanIx)

      // Get the latest blockhash
      const latestBlockhash = await connection.getLatestBlockhash()

      // Set the transaction's recent blockhash and fee payer
      transaction.recentBlockhash = latestBlockhash.blockhash
      transaction.feePayer = wallet.publicKey

      // Partially sign the transaction with the baseTokenKeypair
      transaction.partialSign(vestingPlanKeypair)

      return { transaction, vestingPlanKeypair }
    },
    [
      wallet.publicKey,
      vestingArgs,
      getCreateStakingInstruction,
      getCreateStakePositionInstruction,
      connection
    ]
  )

  const {
    isPending: isCreatingVestingPlan,
    mutateAsync: createVestingPlanAsync,
    reset: resetCreateVestingPlan
  } = useMutation({
    mutationFn: async ([marketAddress, baseTokenAddress]: [string, string]) => {
      if (!wallet.publicKey) throw new Error('Wallet not connected')
      if (!vestingArgs) throw new Error('vestingArgs not provided')

      const { transaction, vestingPlanKeypair } =
        await getCreateVestingPlanTransaction(marketAddress, baseTokenAddress)

      const signature = await wallet.sendTransaction(transaction, connection)

      addTransactionToast({
        chain,
        description: `Created vesting plan`,
        hash: signature,
        walletAddress: wallet.publicKey.toBase58()
      })

      const result = await connection.confirmTransaction(signature, 'processed')

      if (result.value.err) {
        throw new Error(result.value.err.toString())
      }

      return {
        signature,
        vestingPlanAddress: vestingPlanKeypair.publicKey.toBase58()
      }
    }
  })

  return {
    createVestingPlanAsync,
    isCreatingVestingPlan,
    resetCreateVestingPlan
  }
}

export default useCreateVestingPlan
