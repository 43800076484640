import { Show } from '@chakra-ui/react'
import AccountBlockedModal from 'components/AccountBlockedModal'
import Footer from 'components/Footer'
import Header from 'components/Header'
import MobileBottomTabBar from 'components/MobileBottomTabBar'
import ReferralHandler from 'components/ReferralHandler'
import ScrollToTop from 'components/ScrollToTop'
import useLoadOriginCountry from 'hooks/useLoadOriginCountry'
import NotFound from 'pages/404'
import CreateToken from 'pages/CreateToken'
import Portfolio from 'pages/Portfolio'
import Referrals from 'pages/Referrals'
import Stake from 'pages/Stake'
import TokenMill from 'pages/TokenMill'
import TokenMillDetail from 'pages/TokenMillDetail'
import Vesting from 'pages/Vesting'
import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

const ElementWithHeader = ({ element }: { element: JSX.Element }) => {
  return (
    <>
      <Header />
      {element}
    </>
  )
}

export const App = () => {
  useLoadOriginCountry()

  return (
    <BrowserRouter>
      <ScrollToTop />
      <AccountBlockedModal />
      <ReferralHandler />
      <Routes>
        <Route
          path="/404"
          element={<ElementWithHeader element={<NotFound />} />}
        />
        <Route
          path="/"
          element={<ElementWithHeader element={<TokenMill />} />}
        />
        <Route
          path="/:chain/:marketAddress"
          element={<ElementWithHeader element={<TokenMillDetail />} />}
        />
        <Route
          path="/:chain/:marketAddress/stake"
          element={<ElementWithHeader element={<Stake />} />}
        />
        <Route
          path="/:chain/:marketAddress/vesting"
          element={<ElementWithHeader element={<Vesting />} />}
        />
        <Route
          path="/new"
          element={<ElementWithHeader element={<CreateToken />} />}
        />
        <Route
          path="/referrals"
          element={<ElementWithHeader element={<Referrals />} />}
        />
        <Route
          path="/portfolio"
          element={<ElementWithHeader element={<Portfolio />} />}
        />
        {/* Unknown paths */}
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
      <Footer />
      <Show below="lg">
        <MobileBottomTabBar />
      </Show>
    </BrowserRouter>
  )
}
