import { useQuery } from '@tanstack/react-query'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { MarketData } from 'types/dexbarn'
import { useDebounce } from 'use-debounce'

interface UseSearchMarketsProps {
  query: string
}

const useSearchMarkets = ({ query }: UseSearchMarketsProps) => {
  const [debouncedQuery] = useDebounce(query, 300)

  const fetchMarkets = useDexbarnGet<MarketData[]>(
    `/v1/markets/search?query=${debouncedQuery}&chain=avalanche&excludeLowVolumeMarkets=false`
  )

  return useQuery({
    enabled: debouncedQuery.length > 2,
    queryFn: () => fetchMarkets(),
    queryKey: ['SearchMarkets', debouncedQuery]
  })
}

export default useSearchMarkets
