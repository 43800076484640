import { LinkIcon } from '@chakra-ui/icons'
import { Button, useClipboard } from '@chakra-ui/react'
import React from 'react'
import { Chain } from 'types/dexbarn'

interface Props {
  chain: Chain
  code: string
  marketAddress?: string
}

const CopyReferredURLButton = ({ chain, code, marketAddress }: Props) => {
  const { onCopy } = useClipboard(
    marketAddress
      ? `${location.origin}/${chain}/${marketAddress}?r=${code}`
      : `${location.origin}?r=${code}`
  )

  return (
    <Button
      leftIcon={<LinkIcon />}
      size="xs"
      variant="boxShadowFlat"
      onClick={onCopy}
    >
      Copy URL
    </Button>
  )
}

export default CopyReferredURLButton
