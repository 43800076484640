import {
  Box,
  Button,
  Divider,
  Flex,
  Hide,
  HStack,
  IconButton,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useWallet } from '@solana/wallet-adapter-react'
import ConnectWalletModal from 'components/ConnectWalletModal'
import MobileAccountMenu from 'components/MobileAccountMenu'
import WalletMenu from 'components/WalletMenu'
import PopoverSettingsMenu from 'components/WalletMenu/SettingScreen/PopoverSettingsScreen'
import React from 'react'
import { MySettingsIcon } from 'theme/icons'
import { useAccount } from 'wagmi'

const ConnectButton = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const { isConnected: isEvmConnected } = useAccount()
  const { connected: isSolanaConnected } = useWallet()
  const isConnected = isEvmConnected || isSolanaConnected

  return (
    <>
      <ConnectWalletModal isOpen={isOpen} onClose={onClose} />

      {isConnected ? (
        <>
          <Hide below="lg">
            <WalletMenu />
          </Hide>
          <Hide above="lg">
            <MobileAccountMenu />
          </Hide>
        </>
      ) : (
        <>
          <Hide below="lg">
            <HStack
              spacing={0}
              flexShrink={0}
              bg="blueGray"
              _dark={{ bg: 'charcoal' }}
            >
              <Button
                data-cy="connect-wallet-button"
                variant="boxShadowFlat"
                onClick={onOpen}
              >
                <Text>
                  [<Trans>Connect Wallet</Trans>]
                </Text>
              </Button>
              <Divider
                orientation="vertical"
                h="24px"
                ml={1}
                bg="bgSecondary"
              />
              <PopoverSettingsMenu
                popoverTrigger={
                  <IconButton
                    aria-label="settings menu"
                    icon={<MySettingsIcon />}
                    variant="boxShadowFlat"
                  />
                }
              />
            </HStack>
          </Hide>
          <Hide above="lg">
            <IconButton
              bg="transparent"
              aria-label="connect-wallet"
              w="full"
              onClick={onOpen}
            >
              <Flex alignItems="center" pos="relative">
                <Text>[Wallet]</Text>
                <Box
                  pos="absolute"
                  top={-1}
                  boxSize={2}
                  right={-3}
                  borderRadius="full"
                  bgColor="candleRed"
                />
              </Flex>
            </IconButton>
          </Hide>
        </>
      )}
    </>
  )
}

export default ConnectButton
