import { BN } from '@coral-xyz/anchor'
import { PublicKey } from '@solana/web3.js'

export const SCALE = new BN(1e10)

export const TM_CONFIG = new PublicKey(
  '8924mCgUTs7DE9UzNmN6bNFAdKmviqqe22Cx2C1abbPf'
)

export const TM_PROTOCOL_FEE_RECIPIENT = new PublicKey(
  '7zin4bf28fEvUTbcvuDXVff7MvkTXahQRAYHJPMHLVWZ'
)
