import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Heading, HStack, Link, Text, VStack } from '@chakra-ui/react'
import useGetMarket from 'hooks/tokenmill/useGetMarket'
import React from 'react'
import { shortenAddress } from 'utils/addresses'
import { getBlockExplorer } from 'utils/chains'
import { formattedNum } from 'utils/format'

interface AnalyticsRowProps {
  title: string
  link?: string
  secondaryValue?: string
  value?: string
}

const AnalyticsRow = ({
  link,
  secondaryValue,
  title,
  value
}: AnalyticsRowProps) => {
  const Value = () => (
    <HStack>
      <Text>{value}</Text>
      {secondaryValue ? (
        <Text textColor="textSecondary">{secondaryValue}</Text>
      ) : null}
    </HStack>
  )

  return (
    <HStack
      w="full"
      justify="space-between"
      fontSize="sm"
      borderBottom="1px solid"
      borderBottomColor="border"
      borderBottomStyle="dashed"
    >
      <Text textColor="textSecondary">{title}</Text>

      {link ? (
        <Link isExternal href={link}>
          <HStack>
            <Value />
            <ExternalLinkIcon />
          </HStack>
        </Link>
      ) : (
        <Value />
      )}
    </HStack>
  )
}

interface TokenMillDetailAnalyticsProps {
  market?: ReturnType<typeof useGetMarket>['data']
}

const TokenMillDetailAnalytics = ({
  market
}: TokenMillDetailAnalyticsProps) => {
  return (
    <VStack p={{ base: 4, md: 6 }} align="flex-start" w="full">
      <Heading size="sm" mb={2}>
        Analytics
      </Heading>

      <AnalyticsRow
        title="Current Price"
        secondaryValue={
          market?.baseTokenPriceUsd !== undefined
            ? `~${formattedNum(market.baseTokenPriceUsd, {
                usd: true
              })}`
            : undefined
        }
        value={
          market?.baseTokenPriceNative !== undefined
            ? `${formattedNum(market.baseTokenPriceNative, {
                places: 5
              })} AVAX`
            : undefined
        }
      />

      <AnalyticsRow
        title="Market Cap"
        value={
          market?.marketCapUsd !== undefined
            ? formattedNum(market.marketCapUsd, { usd: true })
            : undefined
        }
      />

      <AnalyticsRow
        title="Liquidity"
        value={
          market?.liquidityUsd !== undefined
            ? formattedNum(market.liquidityUsd, { usd: true })
            : undefined
        }
      />

      <AnalyticsRow
        title="Circulating Supply"
        value={
          market?.circulatingSupply !== undefined &&
          market?.totalSupply !== undefined
            ? `${formattedNum(market.circulatingSupply, {
                places: 2
              })} / ${formattedNum(market.totalSupply)}`
            : undefined
        }
      />

      <AnalyticsRow
        title="Token"
        value={shortenAddress(market?.baseToken.address)}
        link={
          market
            ? getBlockExplorer(
                market.baseToken.address,
                market.chain,
                'address'
              ).url
            : undefined
        }
      />

      <AnalyticsRow
        title="Market"
        value={shortenAddress(market?.marketAddress)}
        link={
          market
            ? getBlockExplorer(market.marketAddress, market.chain, 'address')
                .url
            : undefined
        }
      />

      <AnalyticsRow
        title="Creation Time"
        value={
          market?.createdAt
            ? new Date(market.createdAt).toLocaleString()
            : undefined
        }
      />

      <AnalyticsRow
        title="Creator"
        value={shortenAddress(market?.creator)}
        link={
          market
            ? getBlockExplorer(market.creator, market.chain, 'address').url
            : undefined
        }
      />
    </VStack>
  )
}

export default TokenMillDetailAnalytics
