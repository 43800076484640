import {
  Flex,
  HStack,
  Image,
  Link,
  Skeleton,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react'
import CopyReferredURLButton from 'components/CopyReferredURLButton'
import useGetUserReferralCodes from 'hooks/referrals/useGetUserReferralCodes'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Chain } from 'types/dexbarn'

interface TokenMillDetailHeaderProps {
  chain: Chain
  marketAddress: string
  discordUrl?: string
  telegramUrl?: string
  token?: {
    address: string
    name: string
    symbol: string
  }
  tokenLogoUrl?: string
  twitterUrl?: string
  websiteUrl?: string
}

const TokenMillDetailHeader = ({
  chain,
  discordUrl,
  marketAddress,
  telegramUrl,
  token,
  tokenLogoUrl,
  twitterUrl,
  websiteUrl
}: TokenMillDetailHeaderProps) => {
  const hasSocialUrls =
    !!twitterUrl || !!discordUrl || !!telegramUrl || !!websiteUrl

  const isStakeSelected = location.pathname.includes('/stake')
  const isVestingSelected = location.pathname.includes('/vesting')

  const { data: userReferralCodes } = useGetUserReferralCodes()

  return (
    <Stack
      w="full"
      spacing={4}
      px={{ base: 4, md: 6 }}
      py={{ base: 4, md: 6 }}
      flexDir={{ base: 'column', md: 'row' }}
      justify="space-between"
      align={{ base: 'flex-start', md: 'center' }}
    >
      <VStack align="flex-start" fontSize="sm" spacing={4}>
        <HStack spacing={3}>
          <Link as={NavLink} to="/" textColor="textSecondary">
            {`<- Tokens`}
          </Link>

          {userReferralCodes?.[chain] ? (
            <CopyReferredURLButton
              code={userReferralCodes?.[chain]}
              chain={chain}
              marketAddress={marketAddress}
            />
          ) : null}
        </HStack>

        <HStack spacing={3}>
          <Link as={NavLink} to={`/${chain}/${marketAddress}`}>
            <Skeleton isLoaded={!!token}>
              <HStack minW="80px">
                <Image src={tokenLogoUrl} boxSize={6} objectFit="contain" />
                <Text>{token?.name}</Text>
                <Text textColor="textSecondary">{token?.symbol}</Text>
              </HStack>
            </Skeleton>
          </Link>

          <Text textColor="textSecondary">/</Text>

          <Link
            as={NavLink}
            to={`/${chain}/${marketAddress}/stake`}
            textColor={isStakeSelected ? 'textPrimary' : 'textSecondary'}
          >
            Stake
          </Link>

          <Text textColor="textSecondary">/</Text>

          <Link
            as={NavLink}
            to={`/${chain}/${marketAddress}/vesting`}
            textColor={isVestingSelected ? 'textPrimary' : 'textSecondary'}
          >
            Vesting
          </Link>
        </HStack>
      </VStack>

      {hasSocialUrls && (
        <Flex gap={4} fontSize="sm" textColor="textSecondary">
          {twitterUrl && (
            <Link href={twitterUrl} isExternal>
              /TWITTER
            </Link>
          )}

          {discordUrl && (
            <Link href={discordUrl} isExternal>
              /DISCORD
            </Link>
          )}

          {telegramUrl && (
            <Link href={telegramUrl} isExternal>
              /TELEGRAM
            </Link>
          )}

          {websiteUrl && (
            <Link href={websiteUrl} isExternal>
              /WEBSITE
            </Link>
          )}
        </Flex>
      )}
    </Stack>
  )
}

export default TokenMillDetailHeader
