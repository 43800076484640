import { AxiosError } from 'axios'
import useAddErrorPopup from 'hooks/useAddErrorPopup'
import { useDexbarnClient } from 'hooks/useDexbarn'
import { useCallback } from 'react'
import {
  AuthTokens,
  Chain,
  PatchMarketArgs,
  PatchMarketResponse
} from 'types/dexbarn'
import { useAccount } from 'wagmi'

const usePatchMarket = (): ((
  marketAddress: string,
  authTokens: AuthTokens,
  chain: Chain,
  args: PatchMarketArgs
) => Promise<PatchMarketResponse>) => {
  const client = useDexbarnClient()
  const account = useAccount()

  const addErrorPopup = useAddErrorPopup()

  return useCallback(
    async (
      marketAddress: string,
      authTokens: AuthTokens,
      chain: Chain,
      args: PatchMarketArgs
    ): Promise<PatchMarketResponse> => {
      if (!account.address) {
        return Promise.reject('Wallet not connected')
      }

      try {
        const response = await client.patch<PatchMarketResponse>(
          `/v1/markets/${chain}/${marketAddress}`,
          args,
          { headers: { Authorization: `Bearer ${authTokens.accessToken}` } }
        )
        return response.data
      } catch (e) {
        if (e instanceof AxiosError && e.response?.data.detail) {
          addErrorPopup({
            subtitle: e.response?.data.detail,
            summary: 'Unable to patch market'
          })
        } else if (e instanceof Error) {
          addErrorPopup({
            subtitle: e.message,
            summary: 'Unable to patch market'
          })
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [account.address, client, addErrorPopup]
  )
}

export default usePatchMarket
