import { Chain, MarketData } from 'types/dexbarn'

interface UseGetMarketFromBarnProps {
  chain: Chain
  enabled: boolean
  marketAddress: string
}

// TODO: implement useGetMarketFromBarn when API ready
const useGetMarketFromBarn = ({}: UseGetMarketFromBarnProps) => {
  return {
    data: {
      baseMarketCapUsd: 30.445602606875937,
      baseToken: {
        address: '5Y92iJmV9AA79KL2pWnRGXwo1QCNr5Risr59t4XdMPRi',
        decimals: 6,
        name: 'a pure gem',
        priceNative: '0.01458128239198722186713506833',
        priceUsd: 0.32085251491256495,
        symbol: 'GEM'
      },
      baseTokenPctChange: 0,
      chain: 'solana',
      circulatingSupply: 94.88971160214413,
      description: '',
      discordUrl: '',
      iconUrl:
        'https://cdn-fuji.traderjoexyz.com/tokenmill/icon/0xbd01f28226254a94e42a987a81e5aa36d1821116_0532c3c1-1268-4057-9a46-31c71786506c.jpeg',
      marketAddress: 'BqoSNRAMxRabEPhFbBm7HUqPefHPzMD71zHYAKYfwDpX',
      marketCreatedAt: '2024-08-30T10:07:27+00:00',
      name: 'GEM-SOL',
      numberAsks: 0,
      numberBids: 0,
      numberBuyers: 0,
      numberSellers: 0,
      numberSwaps: 0,
      protocolShare: 100000000000000000,
      quoteLiquidityNative: 1.2527711989235704,
      quoteLiquidityUsd: 29.62892204570997,
      quoteToken: {
        address: '6aZFoAoWWxDy6UibzLQHry94CHb9hMm6Ne59xM29Z141',
        decimals: 9,
        name: 'Solana',
        priceNative: '1',
        priceUsd: 23.65070499,
        symbol: 'SOL'
      },
      reserveBase: 999905.1102883979,
      reserveQuote: 1.2527711989235704,
      telegramUrl: '',
      twitterUrl: '',
      volumeAskNative: '0',
      volumeAskUsd: 0,
      volumeBidNative: '0',
      volumeBidUsd: 0,
      volumeNative: '0',
      volumeUsd: 0,
      websiteUrl: ''
    } satisfies MarketData,
    isLoading: false
  }
}

export default useGetMarketFromBarn
