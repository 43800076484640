import { Box, Image, ImageProps } from '@chakra-ui/react'
import AvaxLogo from 'assets/avalanche.svg'
import EtherLogo1x from 'assets/ether_1x.webp'
import EtherLogo2x from 'assets/ether_2x.webp'
import EtherLogo3x from 'assets/ether_3x.webp'
import SolanaLogo from 'assets/solana.jpg'
import PlaceholderLogo from 'assets/token-logo-placeholder.svg'
import React from 'react'
import { Chain } from 'types/dexbarn'
import { getAddress, isAddress, zeroAddress } from 'viem'

const getTokenLogoURL = (address: string) =>
  `https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/${address}/logo.png`

interface CurrencyLogoProps {
  address?: string
  chain?: Chain
  logoURI?: string
  symbol?: string
}

const ChainIcon = ({ chain }: { chain: Chain }) => {
  const iconProps = {
    border: '1px solid white',
    borderRadius: 'full',
    bottom: '-4px',
    height: '30%',
    maxHeight: '24px',
    maxWidth: '24px',
    minHeight: '16px',
    minWidth: '16px',
    position: 'absolute' as const,
    right: '-4px',
    width: '30%'
  }

  switch (chain) {
    case 'avalanche':
      return <Image src={AvaxLogo} alt="Avalanche" {...iconProps} />
    case 'solana':
      return <Image src={SolanaLogo} alt="Solana" {...iconProps} />
  }
}

const CurrencyLogo = ({
  address,
  chain,
  logoURI,
  symbol,
  ...props
}: CurrencyLogoProps & ImageProps) => {
  const logo = React.useMemo(() => {
    if (symbol === 'AVAX' && (!address || address === zeroAddress)) {
      return <Image alt="avalanche-logo" src={AvaxLogo} {...props} />
    }

    if (symbol === 'SOL' && (!address || address === zeroAddress)) {
      return (
        <Image alt="solana-logo" src={SolanaLogo} rounded="full" {...props} />
      )
    }

    if (symbol === 'ETH' && (!address || address === zeroAddress)) {
      return (
        <Image
          alt="ether-logo"
          fallbackSrc={EtherLogo3x}
          srcSet={`${EtherLogo1x} 1x ${EtherLogo2x} 2x ${EtherLogo3x} 3x`}
          {...props}
        />
      )
    }

    if (logoURI) {
      return (
        <Image
          alt={`${symbol}-token-logo`}
          src={logoURI}
          borderRadius="full"
          {...props}
        />
      )
    }

    if (!address || !isAddress(address)) {
      return (
        <Image
          alt="placeholder-logo"
          borderRadius="full"
          bg="white"
          src={PlaceholderLogo}
          {...props}
        />
      )
    }

    return (
      <Image
        alt={`${symbol}-token-logo`}
        src={getTokenLogoURL(getAddress(address))}
        borderRadius="full"
        fallback={
          <Image
            alt="placeholder-token-logo"
            src={PlaceholderLogo}
            borderRadius="full"
            bg="white"
            {...props}
          />
        }
        fallbackStrategy="onError"
        {...props}
      />
    )
  }, [address, symbol, props, logoURI])

  return (
    <Box position="relative" display="inline-block">
      {logo}
      {chain && <ChainIcon chain={chain} />}
    </Box>
  )
}

export default CurrencyLogo
