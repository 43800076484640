import { Box, Divider, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import ColorModeButton from 'components/Header/ColorModeButton'
import React from 'react'
import { ArrowLeftIcon } from 'theme/icons'

import WalletMenuItem from '../ConnectedScreen/WalletMenuItem'
import LanguageMenu from './LanguageMenu'

interface SettingsScreenProps {
  hideHeader?: boolean
  onBackClick?: () => void
}

const SettingsScreen = ({ hideHeader, onBackClick }: SettingsScreenProps) => {
  return (
    <>
      {!hideHeader ? (
        <HStack align="center" px={3} pb={3}>
          {onBackClick ? (
            <WalletMenuItem columnGap={4} onClick={onBackClick}>
              <ArrowLeftIcon />
              <Heading size="sm">
                <Trans>Settings</Trans>
              </Heading>
            </WalletMenuItem>
          ) : (
            <Heading size="sm">
              <Trans>Settings</Trans>
            </Heading>
          )}
        </HStack>
      ) : null}

      {!hideHeader ? <Divider /> : null}

      <Box px={{ base: 0, md: 4 }}>
        <HStack pt={{ base: 2, md: 4 }} w="full" justify="space-between">
          <Text fontWeight="bold">
            <Trans>Theme</Trans>
          </Text>
          <ColorModeButton />
        </HStack>
        <HStack pt={6} w="full">
          <VStack w="full" alignItems="flex-start">
            <Text fontWeight="bold">
              <Trans>Language</Trans>
            </Text>
          </VStack>
          <LanguageMenu />
        </HStack>
      </Box>
    </>
  )
}

export default SettingsScreen
